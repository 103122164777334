import React from 'react';
import styled from "styled-components";


import Ellipse from '../../assets/img/Ellipse 21.svg'
import Sphere from '../../assets/img/Sphere-Purple-Glossy.svg'


const CenterStyled = styled.section`
  width: 30%;
  min-width: 30%;
  align-self: center;
  aspect-ratio: 1 / 1;
  position: relative;
  z-index: 1;

  .bg {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: contain;
    left: 0;
    top: 0;
  }

  .sp {
    aspect-ratio: 1 / 1;
    object-fit: contain;
    position: absolute;
    transition: all .5s ease;
    z-index: 2;
  }

  .sp1 {
    width: 11.47%;
    bottom: 3%;
    left: 56%;
  }

  .sp2 {
    width: 9.46%;
    bottom: 1.5%;
    left: 46.27%;
  }

  .sp3, .sp4, .sp5 {
    width: 7.44%;
  }

  .sp3 {
    bottom: 2.5%;
    left: 38.5%;
  }

  .sp4 {
    bottom: 10%;
    left: 35%;
  }

  .sp5 {
    bottom: 5%;
    left: 29%;
  }

  .left {
    .sp1 {
      bottom: 2.7%;
      left: 34.5%;
    }

    .sp2 {
      bottom: 12.5%;
      left: 28%;
    }

    .sp3 {
      bottom: 9.55%;
      left: 21%;
    }

    .sp4 {
      bottom: 26.5%;
      left: 19.5%;
    }

    .sp5 {
      bottom: 18.5%;
      left: 19%;
    }
  }

  .right {
    .sp1 {
      bottom: 24%;
      left: 80%;
    }

    .sp2 {
      bottom: 15%;
      left: 77%;
    }

    .sp3 {
      bottom: 23.5%;
      left: 72%;
    }

    .sp4 {
      bottom: 12%;
      left: 69.5%;
    }

    .sp5 {
      bottom: 5.5%;
      left: 64%;
    }
  }

  //@media screen and (max-width: 1500px) {
  //  width: 30%;
  //  min-width: 30%;
  //  align-self: center;
  //}

  @media screen and (max-width: 960px) {
    display: none;
  }
`;

const Center = ({activeSide, rtl}) => {
    return (
        <CenterStyled background={Ellipse} activeSide>
            <img src={Ellipse} className='bg' alt=""/>
            <div className={`spheres ${activeSide ? rtl ? activeSide === "left" ? "right" : "left" : activeSide : ''}`}>
                <img src={Sphere} className='sp sp1' alt=""/>
                <img src={Sphere} className='sp sp2' alt=""/>
                <img src={Sphere} className='sp sp3' alt=""/>
                <img src={Sphere} className='sp sp4' alt=""/>
                <img src={Sphere} className='sp sp5' alt=""/>
            </div>

        </CenterStyled>
    )
}

export default Center
