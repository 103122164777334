import Step1 from "../assets/img/InformationSystem/sub/Monday/step1.svg";
import Step2 from "../assets/img/InformationSystem/sub/Monday/step2.svg";
import Step3 from "../assets/img/InformationSystem/sub/Saleforce/step3.png";
import Step4 from "../assets/img/InformationSystem/sub/Saleforce/step4.png";

export const mondayProcessSteps = {
    en: [
        {
            number: "Step 1",
            img: Step1,
            title: "Specification",
            description: "At this stage we will invest the maximum effort in defining the work processes, characterizing and learning the customer's needs. \n" +
                "As part of the process, a detailed characterization document will be built with descriptions and detailed flow charts that will allow you and us to understand what the required achievement of the process is. \n" +
                "We believe that with the help of a thorough characterization process we can ensure that the end result is exactly what your organization needs."
        },
        {
            number: "Step 2",
            img: Step2,
            title: "Development and implementation",
            description: "We will build weekly development sprints, each sprint contains a clear set of tasks aimed at achieving the goals we defined together in the characterization document. After each milestone - the project manager reviews the tasks and provides a regular update to receive general feedback. That way you can know that everything is going according to plan and meeting expectations."
        },
        {
            number: "Step 3",
            img: Step1,
            title: "Testing",
            description: "The testing period is 7-14 days, this period is designed to ensure that the system we built for you was best adapted to the needs of your company/organization. During this period - you can check every aspect of the management processes and update on any problem/change you want to solve before the final delivery."
        },
        {
            number: "Step 4",
            img: Step2,
            title: "Launch and support",
            description: "We did it! \n" +
                "In the final and final stage, we will implement the system for you in accordance with the unique work methods and the nature of the company's management, we will characterize your unique needs and enjoy the work process in the best and most efficient way for you."
        },
    ],
    // he: [
    //     {
    //         number: "Step 1",
    //         img: Step1,
    //         title: "Specification",
    //         description: "At this stage we will invest the maximum effort in defining the work processes, characterizing and learning the customer's needs. \n" +
    //             "As part of the process, a detailed characterization document will be built with descriptions and detailed flow charts that will allow you and us to understand what the required achievement of the process is. \n" +
    //             "We believe that with the help of a thorough characterization process we can ensure that the end result is exactly what your organization needs."
    //     },
    //     {
    //         number: "Step 2",
    //         img: Step2,
    //         title: "Development and implementation",
    //         description: "We will build weekly development sprints, each sprint contains a clear set of tasks aimed at achieving the goals we defined together in the characterization document. After each milestone - the project manager reviews the tasks and provides a regular update to receive general feedback. That way you can know that everything is going according to plan and meeting expectations."
    //     },
    //     {
    //         number: "Step 3",
    //         img: Step1,
    //         title: "Testing",
    //         description: "The testing period is 7-14 days, this period is designed to ensure that the system we built for you was best adapted to the needs of your company/organization. During this period - you can check every aspect of the management processes and update on any problem/change you want to solve before the final delivery."
    //     },
    //     {
    //         number: "Step 4",
    //         img: Step2,
    //         title: "Launch and support",
    //         description: "We did it! \n" +
    //             "In the final and final stage, we will implement the system for you in accordance with the unique work methods and the nature of the company's management, we will characterize your unique needs and enjoy the work process in the best and most efficient way for you."
    //     },
    // ],
    ru: [
        {
            number: "Шаг 1",
            img: Step1,
            title: "Технические характеристики",
            description: "На этом этапе мы вложим максимум усилий в определение рабочих процессов, характеристику и изучение потребностей клиента. \n" +
                "В рамках процесса будет создан документ с подробной характеристикой, с описаниями и подробными блок-схемами, которые позволят Вам и нам понять, в чем заключается требуемое достижение процесса. \n" +
                "Мы считаем, что с помощью тщательного процесса определения характеристик мы можем гарантировать, что конечный результат будет именно таким, который нужен Вашей организации."
        },
        {
            number: "Шаг 2",
            img: Step2,
            title: "Развитие и реализация",
            description: "Мы будем строить еженедельные спринты разработки, каждый спринт содержит четкий набор задач, направленных на достижение целей, которые мы определили вместе в документе характеристики. После каждого этапа менеджер проекта просматривает задачи и предоставляет регулярные обновления для получения общей обратной связи. Таким образом, Вы можете быть уверены, что все идет по плану и соответствует ожиданиям."
        },
        {
            number: "Шаг 3",
            img: Step3,
            title: "Тестирование",
            description: "Период тестирования составляет 7-14 дней, этот период предназначен для того, чтобы система, которую мы построили для Вас, была максимально адаптирована к потребностям Вашей компании/организации. В течение этого периода Вы можете проверить каждый аспект процессов управления и обновить информацию о любой проблеме / изменении, которое Вы хотите решить, до окончательной сдачи."
        },
        {
            number: "Шаг 4",
            img: Step4,
            title: "Запуск и поддержка",
            description: "Мы сделали это! \n" +
                "На заключительном и завершающем этапе мы внедрим для Вас систему в соответствии с уникальными методами работы и характером управления компанией, охарактеризуем Ваши уникальные потребности и получим удовольствие от рабочего процесса наилучшим и наиболее эффективным для Вас способом."
        },
    ]
 };
