
import Anton from '../assets/img/team/anton.png';
import Batchen from '../assets/img/team/batchen.png';
import Idan from '../assets/img/team/idan.png';
import Orel from '../assets/img/team/orel.png';
import Raz from '../assets/img/team/raz.png';
import Stav from '../assets/img/team/stav.png';
import Valeria from '../assets/img/team/valeria.png';
import Yarin from '../assets/img/team/yarin.png';
import Vlada from '../assets/img/team/vlada.png';
import Nick from '../assets/img/team/nick.png';
import Elena from '../assets/img/team/elena.png';
import Serega from '../assets/img/team/serega.jpg';
import Silifonov from '../assets/img/team/silifonov.png';
import Boichenko from '../assets/img/team/boichenko.png';
import Max from '../assets/img/team/max.png';
import Obed from '../assets/img/team/obed.png';
import Row from '../assets/img/team/row.png';
import Obare from '../assets/img/team/obare.png';
import Hillary from '../assets/img/team/hilary.png';
import Brian from '../assets/img/team/brian.png';
import Leehe from '../assets/img/team/leehe.png';
import Yosef from '../assets/img/team/yosef.png';
import Efrat from '../assets/img/team/efrat.png';
import Ofir from '../assets/img/team/ofir.png';
import Omer from '../assets/img/team/omer.png';
import Yael from '../assets/img/team/yael.png';
import Dog from '../assets/img/team/dog.png';

export const team = {
    en: {
        israel: {
            name: "Team Israel",
            team: [
                {
                    teamMember: 'Idan Malul',
                    position: 'Chief Executive Officer',
                    email: 'idan@productive.co.il',
                    img: Idan
                },
                {
                    teamMember: 'Orel Mizrahi',
                    position: 'Chief Operation Officer',
                    email: 'orel@productive.co.il',
                    img: Orel
                },
                {
                    teamMember: 'Stav Durani',
                    position: 'Head of Web Dev',
                    email: 'stav@productive.co.il',
                    img: Stav
                },
                {
                    teamMember: 'Yael Lustig',
                    position: 'Head of Salesforce',
                    email: 'yael@productive.co.il',
                    img: Yael
                },
                {
                    teamMember: 'Valeria Gulin',
                    position: 'Office Operations Manager',
                    email: 'valeria@productive.co.il',
                    img: Valeria
                },
                {
                    teamMember: 'Raz Cohen',
                    position: 'Full Stack Developer',
                    email: 'raz@productive.co.il',
                    img: Raz
                },
                {
                    teamMember: 'Yarin Maimon',
                    position: 'Full Stack Developer',
                    email: 'yarin@productive.co.il',
                    img: Yarin
                },

                {
                    teamMember: 'Leehe Yehuda',
                    position: 'Solution Engineer',
                    email: '',
                    img: Leehe
                },
                {
                    teamMember: 'Batchen Friedman',
                    position: 'UX / UI Designer',
                    email: 'batchen@productive.co.il',
                    img: Batchen
                },
                {
                    teamMember: 'Yosef Lifshitz ',
                    position: 'Sales Managers',
                    email: '',
                    img: Yosef
                },
                {
                    teamMember: 'Efrat Ben Arush',
                    position: 'Solutions Engineer',
                    email: '',
                    img: Efrat
                },
                {
                    teamMember: 'Ofir',
                    position: 'Salesforce developer',
                    email: '',
                    img: Ofir
                },
                {
                    teamMember: 'Omer Unger',
                    position: 'Product Designer',
                    email: '',
                    img: Omer
                },
                {
                    teamMember: 'Drop',
                    position: 'The Dog',
                    email: '',
                    img: Dog
                },
            ]
        },
        ukraine: {
            name: "Team Ukraine",
            team: [
                {
                    teamMember: 'Max Axuh',
                    position: 'Front-End Developer',
                    email: '',
                    img: Max
                },
                {
                    teamMember: 'Vladyslava Pylypenko',
                    position: 'Front-End Developer',
                    email: '',
                    img: Vlada
                },
                {
                    teamMember: 'Anton Prusov',
                    position: 'Front-End Developer',
                    email: 'anton@productive.co.il',
                    img: Anton
                },
                {
                    teamMember: 'Oleksіj Silifonov',
                    position: 'Front-End Developer',
                    email: '',
                    img: Silifonov
                },
                {
                    teamMember: 'Elena Moroz',
                    position: 'Front-End Developer',
                    email: '',
                    img: Elena
                },
                {
                    teamMember: 'Eugene Boichenko',
                    position: 'Back-End Developer',
                    email: '',
                    img: Boichenko
                },
                {
                    teamMember: 'Nick Zhulinskyi',
                    position: 'Back-End Developer',
                    email: '',
                    img: Nick
                },
                {
                    teamMember: 'Serhii Mashuk',
                    position: 'Back-End Developer',
                    email: '',
                    img: Serega
                },
            ]
        },
        kenya: {
            name: "Team Kenya & Ghana",
            team: [
                {
                    teamMember: 'Obed Askulan',
                    position: 'Full Stack Developer',
                    email: '',
                    img: Obed
                },
                {
                    teamMember: 'Row Beloku',
                    position: 'Front-End Developer',
                    email: '',
                    img: Row
                },
                {
                    teamMember: 'Eugene Obare',
                    position: 'Mobile Developer',
                    email: '',
                    img: Obare
                },
                {
                    teamMember: 'Hilary Kimaita',
                    position: 'Mobile Developer',
                    email: '',
                    img: Hillary
                },
                {
                    teamMember: 'Brian Kwendo',
                    position: 'Back-End Developer',
                    email: '',
                    img: Brian
                },
            ]
        },
    },
    // he: [
    //     {
    //         teamMember: 'Idan Malul',
    //         position: 'Chief Executive Officer',
    //         email: 'idan@productive.co.il',
    //         img: Idan
    //     },
    //     {
    //         teamMember: 'Orel Mizrahi',
    //         position: 'Chief Operation Officer',
    //         email: 'orel@productive.co.il',
    //         img: Orel
    //     },
    //     {
    //         teamMember: 'Valeria Gulin',
    //         position: 'Office Operations Manager',
    //         email: 'valeria@productive.co.il',
    //         img: Valeria
    //     },
    //     {
    //         teamMember: 'Raz Cohen',
    //         position: 'Full Stack Developer',
    //         email: 'raz@productive.co.il',
    //         img: Raz
    //     },
    //     {
    //         teamMember: 'Yarin Maimon',
    //         position: 'Full Stack Developer',
    //         email: 'yarin@productive.co.il',
    //         img: Yarin
    //     },
    //     {
    //         teamMember: 'Stav Durani',
    //         position: 'Head of Web Dev',
    //         email: 'stav@productive.co.il',
    //         img: Stav
    //     },
    //     {
    //         teamMember: 'Batchen Friedman',
    //         position: 'UX / UI Designer',
    //         email: 'batchen@productive.co.il',
    //         img: Batchen
    //     },
    //     {
    //         teamMember: 'Anton Prusov',
    //         position: 'Front-End Developer',
    //         email: 'anton@productive.co.il',
    //         img: Anton
    //     }
    // ],
    ru: {
        israel: {
            name: "Team Israel",
            team: [
                {
                    teamMember: 'Idan Malul',
                    position: 'Chief Executive Officer',
                    email: 'idan@productive.co.il',
                    img: Idan
                },
                {
                    teamMember: 'Orel Mizrahi',
                    position: 'Chief Operation Officer',
                    email: 'orel@productive.co.il',
                    img: Orel
                },
                {
                    teamMember: 'Stav Durani',
                    position: 'Head of Web Dev',
                    email: 'stav@productive.co.il',
                    img: Stav
                },
                {
                    teamMember: 'Yael Lustig',
                    position: 'Head of Salesforce',
                    email: 'yael@productive.co.il',
                    img: Yael
                },
                {
                    teamMember: 'Valeria Gulin',
                    position: 'Office Operations Manager',
                    email: 'valeria@productive.co.il',
                    img: Valeria
                },
                {
                    teamMember: 'Raz Cohen',
                    position: 'Full Stack Developer',
                    email: 'raz@productive.co.il',
                    img: Raz
                },
                {
                    teamMember: 'Yarin Maimon',
                    position: 'Full Stack Developer',
                    email: 'yarin@productive.co.il',
                    img: Yarin
                },

                {
                    teamMember: 'Leehe Yehuda',
                    position: 'Solution Engineer',
                    email: '',
                    img: Leehe
                },
                {
                    teamMember: 'Batchen Friedman',
                    position: 'UX / UI Designer',
                    email: 'batchen@productive.co.il',
                    img: Batchen
                },
                {
                    teamMember: 'Yosef Lifshitz ',
                    position: 'Sales Managers',
                    email: '',
                    img: Yosef
                },
                {
                    teamMember: 'Efrat Ben Arush',
                    position: 'Solutions Engineer',
                    email: '',
                    img: Efrat
                },
                {
                    teamMember: 'Ofir',
                    position: 'Salesforce developer',
                    email: '',
                    img: Ofir
                },
                {
                    teamMember: 'Omer Unger',
                    position: 'Product Designer',
                    email: '',
                    img: Omer
                },
                {
                    teamMember: 'Drop',
                    position: 'The Dog',
                    email: '',
                    img: Dog
                },
            ]
        },
        ukraine: {
            name: "Team Ukraine",
            team: [
                {
                    teamMember: 'Max Axuh',
                    position: 'Front-End Developer',
                    email: '',
                    img: Max
                },
                {
                    teamMember: 'Vladyslava Pylypenko',
                    position: 'Front-End Developer',
                    email: '',
                    img: Vlada
                },
                {
                    teamMember: 'Anton Prusov',
                    position: 'Front-End Developer',
                    email: 'anton@productive.co.il',
                    img: Anton
                },
                {
                    teamMember: 'Oleksіj Silifonov',
                    position: 'Front-End Developer',
                    email: '',
                    img: Silifonov
                },
                {
                    teamMember: 'Elena Moroz',
                    position: 'Front-End Developer',
                    email: '',
                    img: Elena
                },
                {
                    teamMember: 'Eugene Boichenko',
                    position: 'Back-End Developer',
                    email: '',
                    img: Boichenko
                },
                {
                    teamMember: 'Nick Zhulinskyi',
                    position: 'Back-End Developer',
                    email: '',
                    img: Nick
                },
                {
                    teamMember: 'Serhii Mashuk',
                    position: 'Back-End Developer',
                    email: '',
                    img: Serega
                },
            ]
        },
        kenya: {
            name: "Team Kenya & Ghana",
            team: [
                {
                    teamMember: 'Obed Askulan',
                    position: 'Full Stack Developer',
                    email: '',
                    img: Obed
                },
                {
                    teamMember: 'Row Beloku',
                    position: 'Front-End Developer',
                    email: '',
                    img: Row
                },
                {
                    teamMember: 'Eugene Obare',
                    position: 'Mobile Developer',
                    email: '',
                    img: Obare
                },
                {
                    teamMember: 'Hilary Kimaita',
                    position: 'Mobile Developer',
                    email: '',
                    img: Hillary
                },
                {
                    teamMember: 'Brian Kwendo',
                    position: 'Back-End Developer',
                    email: '',
                    img: Brian
                },
            ]
        },
    },
    // ru: [
    //     {
    //         teamMember: 'Идан Малул',
    //         position: 'Chief Executive Officer',
    //         email: 'idan@productive.co.il',
    //         img: Idan
    //     },
    //     {
    //         teamMember: 'Орел Мизрахи',
    //         position: 'Chief Operation Officer',
    //         email: 'orel@productive.co.il',
    //         img: Orel
    //     },
    //     {
    //         teamMember: 'Валерия Гулин',
    //         position: 'Office Operations Manager',
    //         email: 'valeria@productive.co.il',
    //         img: Valeria
    //     },
    //     {
    //         teamMember: 'Раз Коэн',
    //         position: 'Full Stack Developer',
    //         email: 'raz@productive.co.il',
    //         img: Raz
    //     },
    //     {
    //         teamMember: 'Ярин Маимон',
    //         position: 'Full Stack Developer',
    //         email: 'yarin@productive.co.il',
    //         img: Yarin
    //     },
    //     {
    //         teamMember: 'Став Дурани',
    //         position: 'Head of Web Dev',
    //         email: 'stav@productive.co.il',
    //         img: Stav
    //     },
    //     {
    //         teamMember: 'Батчен Фридман',
    //         position: 'UX / UI Designer',
    //         email: 'batchen@productive.co.il',
    //         img: Batchen
    //     },
    //     {
    //         teamMember: 'Антон Прусов',
    //         position: 'Front-End Developer',
    //         email: 'anton@productive.co.il',
    //         img: Anton
    //     }
    // ]

};
