import TopAppServices from "../../assets/img/InformationSystem/top_apps_services.svg";
import Data from "../../assets/img/InformationSystem/sections/aboutus/data.svg";
import Monday from "../../assets/img/InformationSystem/sub/Monday/img.svg";
import Salesforce from "../../assets/img/InformationSystem/sub/Saleforce/img.svg";

export const he = {
    translation: {
        "hire": {
            "heading": "גיוס ",
            "headingSpan": "מתכנתים",
            "subHeading": "גיוס מהיר של מתכנתים",
            "conditions1": "בשימוש בטופס זה אתה מסכים עם",
            "conditions2": "מדיניות הפרטיות והתקנון של האתר ",
            "takes_time": "משך - 4 דקות",
            "error": "בבקשה בחר תשובה",
            "doneHeading": "תודה רבה",
            "doneSubHeading": "אנחנו נחזור אליך בהקדם",
            "doneText": "אנחנו נבצע את החיבור הטוב ביותר עבור החברה שלך",
            "multiple": " בחר כמה שתרצה",
            "stepNames": {
                1: "מה גודל החברה ?",
                2: "לאיזה תעשייה שייכת החברה ?",
                3: " איזה סוג מתכנת אתם מחפשים ?",
                4: "עם איזה טכנולוגיות אתם עובדים ?",
                5: "האם התקציב למתכנת גבוה יותר מ- 3000$ ?",
                6: "מה רמת הדחיפות לגיוס ?",
                7: "לכמה זמן תרצו להשתמש במתכנת ?",
                8: "מה אחוז המשרה ?",
                9: "האם אתם מנוסים בעבודה מרחוק ?",
                10: "כמה פרטים עליך",
            },
            "placeholders": {
                "firstName": "שם פרטי",
                "lastName": "שם משפחה",
                "email": "כתובת דוא״ל",
                "mobile": "מספר טלפון",
            }
        },
        "hero_title": {
            0: "אנחנו <highlight>נפתח</highlight> פתרונות דיגיטלים עבור <highlight>האתגר</highlight> העסקי הבא שלך",
            1: "אנחנו <highlight>נרחיב</highlight> את צוות הפיתוח עבור <highlight>הפרויקט</highlight> הבא שלך",
            2: "אנחנו מאמינים בעבודה <highlight>פרודקטיבית</highlight> עבור <highlight>מוצרים</highlight> חדשניים",
        },
        // "our_work": "פרוייקטים",
        // "about_us_headline": "About us",
        // "our_process": "התהליך שלנו",
        // "meet_the_team": "הצוות של פרודקטיב",
        // "testimonials": "אומרים עלינו",
        // "team_slogan": "בזכותם אתם מרוצים",
        // "team_part_slogan": "רוצה להצטרף לפרודקטיב??",
        // "technologies": "טכנולוגיות",
        // "benefits": "Benefits",
        // "our_offices": "המשרדים שלנו",
        // "lets_build": "בואו נבנה א",
        // "description": "Description",
        // "our_friends": "Our friends",
        // "scroll_more": "scroll to see more",
        // "see_more": "see more",
        "hire_heading": "גיוס מתכנתים",
        // "progress": {
        //     1: "Welcome",
        //     2: "Services",
        //     3: "Portfolio",
        //     4: "Process",
        //     5: "Testimonials",
        //     6: "Team",
        //     7: "Contacts"
        // },
        // "nav": {
        //     "get_in_touch": "Get In Touch With Us",
        //     "build": " שירותים",
        //     "services": "שירותים",
        //     "portfolio": "עבודות",
        //     "team": "The team",
        //     "blog": "Blog",
        //     "careers": "קרירה",
        //     "about": "עלינו",
        //     "friends": "Friends"
        // },
        "btn": {
            // "read_more": "הצג עוד",
            // "learn_more": "Learn More",
            // "read_article": "Read The Article",
            // "read_less": "הצג פחות",
            // "see_portfolio": "See Portfolio",
            // "visit_site": "בקר באתר",
            // "see_full_team": "See Full team",
            // "get_free_quote": "Get free quote",
            // "send": "שלח",
            // "see_openings": "המשרות הפתוחות שלנו",
            // "see_careers": "See All Careers",
            // "project_in_progress": "Project is in progress",
            // "more_info": "More info",
            // "see-full-customers": "See Full Customers",
            // "contact-expert": "Contact Expert",
            "get_started": "בואו נתחיל",
            "next": "הבא",
            "home": "עמוד הבית",
        },
    //     "build": {
    //         "title": "השירותים שלנו",
    //         "product": {
    //             "title": "Build a Product",
    //             "description":
    //                 "<p>כל צרכי הפרוייקט באותו בית: תכנון, עיצוב, פיתוח תוכנה, בדיקת היתכנות ויציאה לשוק. </p>",
    //             "mobileDescription":
    //                 "<p>אנחנו בונים מוצרי תוכנה מא' ועד ת'. אנחנו משתמשים במתודולוגיות המתקדמות ביותר לניהול פרויקטים, כמו אג'יל וסקראם. בתהליך, אנחנו מנטרים כל תקלה ובעיה, מודדים את מה שחשוב, ומבצעים אופטימיזציה שוטפת. תהליכי העבודה שלנו מאפשרים לנו לצאת לשוק במהירות - בהשוואה לתהליך פיתוח מסורתי. בכל פרוייקט שאנחנו לוקחים, חשוב לנו לעמוד בזמנים וביעדים - ולהביא כל מוצר לנקודת הסיום.</p>",
    //             "servicesTitle": "מחפש להגדיל את המוצר שלך ולהגדיל את גודלו?",
    //             "servicesDescription": "אנו בונים מוצרי תוכנה מא' ועד ת'. אנו משתמשים במתודולוגיות המתקדמות ביותר לניהול פרויקטים, כגון Agile ו- Scrum. בתהליך אנחנו עוקבים אחר כל תקלה ובעיה, מודדים מה חשוב ומבצעים אופטימיזציה מתמשכת."
    //         },
    //         "team": {
    //             "title": "Build a Team",
    //             "description":
    //                 "<p>מתכנתים במיקור חוץ הוא פתרון מעולה לסטארטאפים או עסקים קטנים שצריכים כוח אדם איכותי במהירות. אנחנו מאפשרים את הגמישות המקסימלית: לגייס ולהכשיר מתכנת לצוות שלכם תוך 7-14 יום בממוצע, בהתאם להיקף שאתם צריכים.</p>",
    //             "mobileDescription":
    //                 "<p>מתכנתים במיקור חוץ הוא פתרון מעולה לסטארטאפים או עסקים קטנים שצריכים כוח אדם איכותי במהירות. אנחנו מאפשרים את הגמישות המקסימלית: לגייס ולהכשיר מתכנת לצוות שלכם תוך 7-14 יום בממוצע, בהתאם להיקף שאתם צריכים.</p>",
    //             "servicesTitle": "מחפש להגדיל את צוות הפיתוח? להגדיל את היקף העבודה?",
    //             "servicesDescription": "צריכים צוות דינמי? זה הזמן לדבר איתנו ! נשמח לדון בצרכים שלך ולעזור לבנות צוות של כישרונות מובילים כדי לעזור לעסק שלך למצות את הפוטנציאל שלו."
    //         }
    //     },
    //     "services": {
    //         "team": "צוות",
    //         "product": "מוצר"
    //     },
    //     "about_us": {
    //         "title": "הסיפור שלנו",
    //         "main_text": "<p>הסיפור של פרודקטיב מתחיל מאהבה גדולה לטכנולוגיה. כמו שפסל אוהב לפסל, כמו שמשורר אוהב לכתוב שירים - אנחנו אוהבים ליצור פתרונות טכנולוגים שהופכים חיים של אנשים לטובים יותר</p>" +
    //             "<p>מבחינתנו, פרודוקטיביות זה מעבר ליעילות. אנחנו לא כאן כדי לכתוב כמה שיותר שורות קוד בכמה שפחות זמן. אלא, להיות דינאמיים בעולם תחרותי, ולהוציא לשוק במהירות האפשרית מוצר תוכנה שעונה בדיוק על הצרכים של הלקוחות, בלי פיצ'רים מיותרים</p>" +
    //             "<p>לשם כך, אנחנו משתמשים במתודולוגיות המתקדמות ביותר לניהול פרויקטים, כמו אג'יל וסקראם. בתהליך, אנחנו מנטרים כל תקלה ובעיה, מודדים את מה שחשוב, ומבצעים אופטימיזציה שוטפת</p>" +
    //             "<p>חשוב לנו להיות שותפים מלאים להצלחה של הלקוחות שלנו. אנחנו לוקחים בעלות ומתייחסים לכל פרוייקט כאילו היה המוצר שלנו. הסטנדרטים שלנו גבוהים, אנחנו מקפידים לעמוד ביעדים ולהביא כל מוצר אל נקודת הסיום</p>" +
    //             "<p>המכלול של הערכים, כוח האדם ושיטות העבודה איפשרו לנו לפרוץ קדימה במהירות. ממשרד קטן בתחילת הדרך, למשרד המעסיק עשרות עובדים, ועובד עם חברות מובילות במשק, כגון קשת 12, אוניברסיטת רייכמן, שנקר, בנק הפועלים, דיסקונט ועוד</p>" +
    //             "<p>אנחנו ממשיכים לגדול, והמשימה שלנו היא לפתח מוצרי תוכנה שישפיעו לטובה על חייהם של מיליוני אנשים</p>",
    //         "banner": {
    //             "title": "<h1>40+ <br> Companies</h1>",
    //             "items": {
    //                 "item0": "User friendly modern & intuitive",
    //                 "item1": "New features, trendy design.",
    //                 "item2": "End to end, high-level development",
    //                 "item3": "24/7 customer services",
    //             }
    //         },
    //         "secondary_text": "צריכים צוות דינמי? זה הזמן לדבר איתנו ! נשמח לדון בצרכים שלך ולעזור לבנות צוות של כישרונות מובילים כדי לעזור לעסק שלך למצות את הפוטנציאל שלו."
    //     },
    //     "contact_form": {
    //         "headline": "<div>we’re <span>here </span>for you and we’re ready to <span> work </span> with you<p/>",
    //         "mob_headline": "Contact us!",
    //         "discuss_needs": "Lets discuss your needs!",
    //         "productive_together": "בואו נהיה פרודקטיבים ביחד",
    //     },
    //     "placeholders": {
    //         "name": "שם מלא",
    //         "phone": "מספר נייד",
    //         "email": "דוא״ל",
    //         "message": "תרגישו חופשי",
    //         "Link": "פורטפוליו"
    //     },
    //     "careers": {
    //         "title": "קרירה",
    //         "heading": "אנחנו המקפצה לשלב הבא בקריירה שלך.",
    //         "main_text": "אנחנו מחפשים מישהו שרוצה להגיע לפריצה הגדולה בקריירה שלו, להשפיע ולהשפיע עם מה שהוא עושה, ואוהב לחקור וללמוד טכנולוגיות חדשות. מישהו שרוצה לעבוד בצוות גלובלי ויודע לפתור בעיות ולעמוד בלחץ."
    //     },
    //     "positions": {
    //         "heading": "Available Positions ",
    //         "see_position": "See position",
    //         "position_description": "Position description",
    //         "requirements": "Requirements"
    //     },
    //      "friendsCategories" : {
    //      "brands": "Brands",
    //      "startups": "Startups"
    //     },
    //     "blog": {
    //         "heading": "THE BLOG",
    //         "title": "Productive Blog",
    //     },
    //     "news": {
    //         "heading": "from the press",
    //         "title": "This is what the press thinks of us",
    //     },
    //     "InformationSystem": {
    //         "heading": "Information System",
    //         "title": "Implementation Of An Information System In Business",
    //         "description": "<p>CRM implementation services are aimed at providing businesses with platform-based or custom CRM solutions to optimize sales, marketing, and customer service processes.</p>" +
    //             "<p>With 8 years of experience in CRM services, ScienceSoft carries out CRM implementation to help companies reduce the costs of lead acquisition and conversion, as well as customer retention costs.</p>",
    //         "img": TopAppServices,
    //         "sections": {
    //             "services": {
    //                 "heading": "Our Services",
    //             },
    //             "products": {
    //                 "heading": "SalesForce products",
    //             },
    //             "about-us": {
    //                 "heading": "About Us",
    //                 "title": "We are partners in your technological %challenges% and %optimize% the work environment",
    //                 "description": "<p>As Consulting partner, Productive creates a unique strategy for your organization that enables CRM as a catalyst to reach your transformational goals.</p>" +
    //                     "<p>We know the technology, the strategy and how it applies to industries. With an eye on innovation, Productive brings in technical expertise early, leveraging years of experience to design, architect and implement a Salesforce strategy that meets your organization’s needs today and builds a plan for the future.</p>",
    //                 "img": Data
    //             },
    //             "customers": {
    //                 "heading": "our customers",
    //                 "subtitle": "official website",
    //             }
    //         },
    //     },
    //     "monday": {
    //         "heading": "MONDAY.COM",
    //         "title": "Join %Monday.Com% System Management Revolution",
    //         "description": "<p>We have the experience and resources to help your organization maximize the value of your monday.com investment. Our team has helped clients with all aspects of using monday.com including onboarding, it consulting, training, and project-based implementation services.</p>",
    //         "img": Monday,
    //     },
    //     "salesforce": {
    //         "heading": "Salesforce",
    //         "title": "Join %Salesforce% System Management Revolution",
    //         "description": "<p>As Salesforce Consulting partner, Productive creates a unique strategy for your organization that enables Salesforce as a catalyst to reach your transformational goals.</p>" +
    //             "<p>We know Salesforce – the technology, the strategy and how it applies to industries. With an eye on innovation, Productive brings in technical expertise early, leveraging years of experience to design, architect and implement a Salesforce strategy that meets your organization’s needs today and builds a plan for the future.</p>",
    //         "img": Salesforce,
    //     }
    }
}