import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import LanguageSwitcher from "../LanguageSwitcher/LanguageSwitcher";
import {useTranslation} from "react-i18next";
import {useWindowSize} from "react-use";
import useDirection from "../../hooks/useDirection";

const Ul = styled.ul`
	overflow-y: scroll;
	list-style: none;
	display: flex;
	flex-flow: column nowrap;
	position: fixed;
	top: 0;
	bottom: 0;
	width: 500px;
	max-width: 100%;
	z-index: 20;
	box-shadow: 0px 10px 29px -12px rgb(101 41 189 / 70%);
	padding: 100px 120px 50px;
	height: 100vh;
	transition: all .4s;
	${({isRtl}) => isRtl ? "left" : "right"}: ${({ open, isRtl }) => (open && isRtl ? '0' : open ? '0' : '-500px')};
	background: #9146FF;
	backdrop-filter: blur(2px);

	li {
		padding: 18px 0;
		font-weight: normal;
		font-size: 1.5rem;
		line-height: 140%;
		text-align: left;
		color: white;
		&:hover {
			border-bottom: 1px solid #C4C4C4;
		}
		&:last-child {
			flex: 1;
			display: flex;
			align-items: flex-end;
			justify-content: center;
			cursor: unset;
			&:hover {
				border: none;
			}
		}
		a {
			width: 100%;
			display: block;
		}
	}

	@media screen and (max-width: 500px) {
		//background: #FFF;
		width: 100%;
		right: ${({ open }) => (open ? '0' : '-120%')};
		//padding: 100px 50px;
		padding: 50px;
		li {
			font-size: 24px;
		}
		//top: 5rem;
		//height: calc(100vh - 80px);
	}
`;

const RightNav = ({ open, toggleMenu }) => {
	const {t} = useTranslation();
	const [show, setShow] = useState(open);
	const {isRtl} = useDirection();
	const screenSize = useWindowSize().width;

	useEffect(() => setShow(open), [open]);

	return (
		<Ul open={show} isRtl={isRtl}>
			<li>
				<Link onClick={() => screenSize >= 0 ? toggleMenu(false) : null} to="/services/team">
					{t('nav.services')}
				</Link>
			</li>
			{/*<li>*/}
			{/*	<Link onClick={() => screenSize >= 0 ? toggleMenu(false) : null} to="/information-system">*/}
			{/*		{t('nav.crm')}*/}
			{/*	</Link>*/}
			{/*</li>*/}
			<li>
				<Link onClick={() => screenSize >= 0 ? toggleMenu(false) : null} to="/portfolio">
					{t('nav.portfolio')}
				</Link>
			</li>
			<li>
				<Link onClick={() => screenSize >= 0 ? toggleMenu(false) : null} to="/clients">
					{t('nav.friends')}
				</Link>
			</li>
			<li>
				<Link onClick={() => screenSize >= 0 ? toggleMenu(false) : null} to="/news">
					{t('nav.news')}
				</Link>
			</li>
			<li>
				<a onClick={() => screenSize >= 0 ? toggleMenu(false) : null} href="https://p.productive.co.il/blog" target="_blank" rel="noreferrer">
					{t('nav.blog')}
				</a>
			</li>
			<li>
				<Link onClick={() => screenSize >= 0 ? toggleMenu(false) : null} to="/team">
					{t('nav.team')}
				</Link>
			</li>

			<li>
				<Link onClick={() => screenSize >= 0 ? toggleMenu(false) : null} to="/about">
					{t('nav.about')}
				</Link>
			</li>
			<li>
				<Link onClick={() => screenSize >= 0 ? toggleMenu(false) : null} to="/careers">
					{t('nav.careers')}
				</Link>
			</li>
			<li>
				<LanguageSwitcher handleChange={() => toggleMenu(false)}/>
			</li>
		</Ul>
	);
};

export default RightNav;
