import React, {useState} from 'react';
import styled from "styled-components";

import ButtonNavbar from '../Buttons/ButtonNavbar';
import {Body1, Heading1} from '../Fonts.Style';
import ServiceIcon from "./ServiceIcon";
import useWindowSize from "../../hooks/useWindowSize";
import TopMobile from "./TopMobile";
import {Trans, useTranslation} from "react-i18next";
import ButtonWhite from "../Buttons/ButtonWhite";
import useDirection from "../../hooks/useDirection";

const SideMenu = styled.div`
  flex-direction: column;
  width: calc(30.5% + 65px);
  min-width: 250px;
  position: relative;
  ${({isRtl}) => isRtl ? "padding-right" : "padding-left"}: ${({side, isRtl}) => side === 'left' ? '0' : '100px'};
  ${({isRtl}) => isRtl ? "padding-left" : "padding-right"}: ${({side, isRtl}) => side === 'left' ? '100px' : '0'};
  z-index: 2;

  > *:not(.icon
  
  ) {
    opacity: .4;
    transition: all .5s;
  }

  &.active {
    opacity: 1;

    > *:not(.icon) {
      opacity: 1;
    }

    .white-btn {
      background: var(--purple2);
      color: var(--white);
    }

    .icon {
      background: #9146FF;
    }

    .imgWrap .img {
      transform: scale(.9);
    }

    //.imgWrap .slide {
    //  animation: 3s linear 0s infinite normal slide;
    //}
  }

  .side-headline {
    font-size: 48px /* 56px -> 3.5rem */;
    line-height: 1.2;
    color: var(--purple1);
    margin: .8125rem 0;
    white-space: ${({lang}) => lang === "en" ? "nowrap" : "initial"};
  }

  .side-text {
    width: 100%;
    margin-bottom: 50px;
    min-height: 170px;
    overflow: hidden;
    transition: all .5s;
    &.full {
      height: unset;
    }
    p {
      line-height: 1.5rem;
    }
  }

  .img-btn-wrapper {
    width: 100%;
    align-self: center;
    display: flex;
    justify-content: flex-start;
  }

  .explore {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    ${({isRtl}) => isRtl ? "left" : "right"}: ${props => props.side === 'left' ? '15%' : 'unset'};
    ${({isRtl}) => isRtl ? "right" : "left"}: ${props => props.side === 'left' ? 'unset' : '15%'};
    color: #BFBFBF;
    font-size: 16px;
    font-weight: bold;
  }

  .imgWrap {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 90%;
    aspect-ratio: 340 / 232;
    overflow: hidden;
    border-radius: .5rem;

    .img {
      position: absolute;
      width: 100%;
      height: 100%;
      max-width: 320px;
      border-radius: .5rem;
      object-fit: contain;
      left: 0;
      top: 0;
      transition: all .5s ease;
      z-index: 1;
    }

    .slide {
      z-index: 2;
      left: 0;
      bottom: -5px;
      width: 200%;
      height: 100%;
      transition: all .5s ease;

      .pieImg {
        left: -4.3%;
        top: 18%;
        width: 23.5%;
        filter: drop-shadow(-5.37787px 0px 10.7557px rgba(0, 0, 0, 0.16));
      }

      .mainImg {
        left: 9%;
        width: 43%;
        top: .8%;
        filter: drop-shadow(-5.37787px 0px 10.7557px rgba(0, 0, 0, 0.16));
      }

      .sliderSecondImg {
        height: 90%;
        bottom: 0;
        right: 0;
      }
    }
  }
  
  .btn-wrap {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    button {
      margin: 0 0 50px;
      font-size: 16px;
    }
  }

  @media screen and (max-width: 1500px) {
    .side-headline {
      font-size: 40px;
    }
  }

  @media screen and (max-width: 1280px) {

    ${({isRtl}) => isRtl ? "padding-right" : "padding-left"}: ${({side, isRtl}) => side === 'left' ? '0' : '75px'};
    ${({isRtl}) => isRtl ? "padding-left" : "padding-right"}: ${({side, isRtl}) => side === 'left' ? '75px' : '0'};
    
    .side-headline {
      font-size: 2rem;
      line-height: 1.5;
    }

    .explore {
      font-size: 13px;
      ${({isRtl}) => isRtl ? "left" : "right"}: ${({side}) => side === 'left' ? '50px' : 'unset'};
      ${({isRtl}) => isRtl ? "right" : "left"}: ${({side}) => side === 'left' ? 'unset' : '50px'};
    }
    
  }
  
  @media screen and (max-width: 960px) {
    min-height: calc(100vh - 100px);
    width: 100%;
    padding: 0;
    margin-top: 0;
    padding-bottom: 50px;
    .explore {
      display: none;
    }
    .side-text {
      margin-bottom: 50px;
      font-size: 18px;
      line-height: 22px;
      height: 110px;
      min-height: 110px;
      p {
        line-height: 22px;
      }
    }
    .imgWrap {
      width: 100%;
    }
    &.active {
      .white-btn {
        background: none;
        color: #9146FF;
        
      }
    }
    &.right .side-headline {
      animation: .3s linear 0s normal mobHeadingSlide;
    }
    .btn-wrap {
      justify-content: center;
    } 
  }

  @media screen and (max-width: 500px) {
    min-height: calc(100vh - 80px);
    .side-text {
      margin-bottom: 20px;
    }
    .white-btn {
      margin-bottom: 10px !important;
    }
  }

  //@keyframes slide {
  //  0% {
  //    left: 0;
  //  }
  //  16% {
  //    left: -105%;
  //  }
  //  16.67% {
  //    left: -100%;
  //  }
  //  50% {
  //    left: -100%;
  //  }
  //  65% {
  //    left: 5%;
  //  }
  //  66.7% {
  //    left: 0;
  //  }
  //}
  @keyframes mobHeadingSlide {
    0% {
      margin-left: 100%;
    }
    100% {
      margin-left: 0;
    }
  }
`;

const ServiceSideMenu = ({img, alignItems, side, returnActive, icon}) => {
    const screenSize = useWindowSize().width;
    const [active, setActive] = useState(false);
    const [fullDesc, setFullDesc] = useState(false);
    const {t, i18n} = useTranslation();
    const {isRtl} = useDirection();

    const handleMouseEnter = () => {
        if (screenSize > 960) {
            setActive(true);
            returnActive(side);
        }
    }

    const handleMouseLeave = () => {
        if (screenSize > 960) {
            setActive(false);
            returnActive(null);
        }
    }

    return (
        <SideMenu
            className={`flex ${side} ${(active || screenSize < 960) ? 'active' : ''}`}
            side={side}
            isRtl={isRtl}
            lang={i18n.language.substring(0, 2)}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {screenSize < 960 && <TopMobile icon={icon} side={side}/>}
            <Heading1 className="side-headline">
                {t(side === "left" ? "build.product.title" : "build.team.title")}
            </Heading1>
            <Body1 className={fullDesc ? 'side-text full' : 'side-text'}>
                {
                    side === "left"
                        ? (
                            <>
                                {screenSize > 960
                                    ? <Trans components={{title: <span className="title"/>}}>build.product.description</Trans>
                                    : <Trans components={{title: <span className="title"/>}}>build.product.mobileDescription</Trans>
                                }

                            </>
                        )
                        : (
                            <>
                                {screenSize > 960
                                    ? <Trans components={{title: <span className="title"/>}}>build.team.description</Trans>
                                    : <Trans components={{title: <span className="title"/>}}>build.team.mobileDescription</Trans>
                                }
                            </>
                        )
                }
            </Body1>
            <div className='flex column img-btn-wrapper'>
                <div className="btn-wrap" onClick={() => setFullDesc(!fullDesc)}>
                    {screenSize > 960
                        ? <ButtonNavbar text={t("btn.read_more")} link={side === "left" ? "/services/product" : "/services/team"} padding={"60px"} />
                        : <ButtonWhite title={fullDesc ? t("btn.read_less") : t("btn.read_more")}/>
                    }
                </div>
                <div className="imgWrap">
                    <img src={img} alt="" className={"img"}/>
                </div>
            </div>
            <ServiceIcon icon={icon} side={side} isRtl={isRtl}></ServiceIcon>
            {/*{!fullDesc && <span className="explore">EXPLORE</span>}*/}
        </SideMenu>
    )
}

export default ServiceSideMenu
