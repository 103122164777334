import quik from '../assets/img/clientsImg/quik.svg';
import quikCoIl from '../assets/img/clientsImg/quik-co-il.svg';
import keshet from '../assets/img/clientsImg/keshet.svg';
import keshetBw from '../assets/img/clientsImg/keshet-bw.svg';
import eco99 from '../assets/img/clientsImg/eco99.svg';
import obli from '../assets/img/clientsImg/obli.svg';
import asal from '../assets/img/clientsImg/asal.svg';
import digital from '../assets/img/clientsImg/digital';
import carpet from '../assets/img/clientsImg/carpet.png';
import shenkar from '../assets/img/clientsImg/shenkar.png';
import kiryat from '../assets/img/clientsImg/kiryat.jpg';
import idc from '../assets/img/clientsImg/idc.png';
import canzon from '../assets/img/clientsImg/canzon.png';
import mornao from '../assets/img/clientsImg/mornao.png';
import proCenter from '../assets/img/clientsImg/proCenter.svg';
import heel from '../assets/img/clientsImg/heel.jpg';
import sea from '../assets/img/clientsImg/sea.png';
import fitnuts from '../assets/img/clientsImg/fitnuts.png';
import or from '../assets/img/clientsImg/or.png';
import syqe from '../assets/img/clientsImg/syqe.jpg';
import wecheck from '../assets/img/clientsImg/wecheck.png';
import meller from '../assets/img/clientsImg/meller.png';
import zoom from '../assets/img/clientsImg/zoom.png';
import Bezalel from '../assets/img/clientsImg/bezales.png';
import Giraffe from '../assets/img/clientsImg/giraffe.png';
import Holon from '../assets/img/clientsImg/holon.png';
import Rosh from '../assets/img/clientsImg/rosh.png';


export const clients = {
    en: [
        {
            companyName: 'Holon municipality',
            img1: Holon,
            img2: '',
            website: 'https://www.holon.muni.il/Pages/default.aspx',
            description: 'Development of the Holon municipality website',
            category: "",
        },
        {
            companyName: 'Bezalel Are Academy',
            img1: Bezalel,
            img2: '',
            website:  "https://www.bezalel.ac.il/",
            description: 'Development of the Bezalel Art Academy website',
            category: "",
        },
        {
            companyName: 'Blue giraffe',
            img1: Giraffe,
            img2: '',
            website: 'https://www.bluegiraffe.co.il/',
            description: "CRM development and support in automation processes for marketing and process management with the brand\'s",
            category: "",
        },
        {
            companyName: 'Rosh-haayin municipality',
            img1: Rosh,
            img2: '',
            website: 'https://www.rosh-haayin.muni.il/',
            description: 'CRM development and support in automation processes',
            category: "",
        },
        {
            companyName: 'Eco99FM',
            img1: eco99,
            img2: eco99,
            website: 'https://eco99fm.maariv.co.il/',
            description: 'Web, Mobile, Car and TV applications for Israeli radio station',
            category: "brand",
        },
        {
            companyName: 'Keshet12',
            img1: keshet,
            img2: keshetBw,
            website: 'https://www.mako.co.il/tv',
            description: 'A promotional app for the leading TV channel in Israel',
            category: "brand",

        },
        {
            companyName: 'Quik',
            img1: quik,
            img2: quikCoIl,
            website: 'https://quik.co.il/',
            description: 'Development of an online store for the sale of consumer products',
            category: "brand",
        },
        {
            companyName: 'OBLI',
            img1: obli,
            img2: '',
            website: 'https://obli.co.il/',
            description: 'B2C fintech startup, which provides digital guarantees with fully digital process. ',
            category: "startup",
        },
        {
            companyName: 'Syqe',
            img1: syqe,
            img2: '',
            website: 'https://www.syqe.com/',
            description: 'CRM development and support in automation processes for marketing and process management with the brand\'s customers',
            category: "startup",
        },
        {
            companyName: 'OR Insurance',
            img1: or,
            img2: '',
            website: 'https://or-ins.co.il/',
            description: 'Development of a digital system for insurance products - car, mortgage, travel.',
            category: "brand",
        },
        {
            companyName: 'ASAL',
            img1: asal,
            img2: '',
            website: '',
            description: 'Development of an Internet system for recruiting and managing foreign workers in Israel',
            category: "startup",
        },
        {
            companyName: 'Digital studio ',
            img1: digital,
            img2: '',
            website: 'https://digitalstudio.co.il/',
            description: 'Development of a system of courses for acting teaching studio',
            category: "brand",
        },
        {
            companyName: 'WeCheck',
            img1: wecheck,
            img2: '',
            website: 'https://wecheck.news/',
            description: 'A digital platform for checking check coverage and background checks for apartment renters',
            category: "startup",
        },
        {
            companyName: 'Meshek Meller',
            img1: meller,
            img2: '',
            website: 'https://meshekmeller.co.il/',
            description: 'Digital store for the purchase of fruits, vegetables and pantry products',
            category: "brand",
        },
        {
            companyName: 'Zoom REI',
            img1: zoom,
            img2: '',
            website: 'https://www.zoom-rei.com/',
            description: 'Establishment of an information system for property management and real estate',
            category: "brand",
        },
        {
            companyName: 'Flying Carpet ',
            img1: carpet,
            img2: '',
            website: 'https://www.flying.co.il/',
            description: 'Development of an internal order management system',
            category: "brand",
        },
        {
            companyName: 'Shenkar',
            img1: shenkar,
            img2: '',
            website: 'https://www.myaguda.co.il/',
            description: 'Development of the Shenkar Student Association website',
            category: "brand",
        },
        {
            companyName: 'Kiryat Ono Student Association',
            img1: kiryat,
            img2: '',
            website: 'https://onostudent.co.il/',
            description: 'Development of the Kiryat Ono Student Association website',
            category: "brand",
        },
        {
            companyName: 'IDC',
            img1: idc,
            img2: '',
            website: 'https://www.runi.ac.il/',
            description: 'Development of the IDC Student Association website',
            category: "brand",
        },
        {
            companyName: 'Canzon',
            img1: canzon,
            img2: '',
            website: 'https://www.canzon.com/',
            description: 'Development of an online store for a global CBD brand',
            category: "startup",
        },
        {
            companyName: 'By Morano',
            img1: mornao,
            img2: '',
            website: 'https://bymorano.com/',
            description: 'Development of an online store for an Israeli fashion brand',
            category: "startup",
        },
        {
            companyName: 'Pro-Center',
            img1: proCenter,
            img2: '',
            website: 'https://procenter.co.il/',
            description: 'Development of an online store for an Israeli care and hair brand',
            category: "startup",
        },
        {
            companyName: 'Heel-Free',
            img1: heel,
            img2: '',
            website: 'https://heel-free.com/',
            description: 'Development of an online store for an Israeli insole brand'
        },
        {
            companyName: 'Sea time ',
            img1: sea,
            img2: '',
            website: 'https://sea-time.co.il/',
            description: 'Development of a website for ordering yachts in a variety of destinations around the world'
        },
        {
            companyName: 'Fitnuts',
            img1: fitnuts,
            img2: '',
            website: 'https://fitnuts.com/',
            description: 'Development of a digital platform for sports and nutrition training',
            category: "startup",
        },



    ],
    // he: [
    //     {
    //         companyName: 'רדיו 99',
    //         img1: eco99,
    //         img2: eco99,
    //         website: 'https://eco99fm.maariv.co.il/',
    //         description: 'אפליקציות אינטרנט, מובייל, רכב וטלוויזיה לתחנת רדיו ישראלית'
    //     },
    //     {
    //         companyName: 'קשת 12',
    //         img1: keshet,
    //         img2: keshetBw,
    //         website: 'https://www.mako.co.il/tv',
    //         description: 'אפליקציית קידום מכירות לערוץ הטלויזיה המוביל בישראל'
    //
    //     },
    //     {
    //         companyName: 'Quik',
    //         img1: quik,
    //         img2: quikCoIl,
    //         website: 'https://quik.co.il/',
    //         description: 'פיתוח חנות אינטרנטית למכירת מוצרי צריכה'
    //     },
    //     {
    //         companyName: 'OBLI',
    //         img1: obli,
    //         img2: '',
    //         website: 'https://obli.co.il/',
    //         description: 'סטארט-אפ פינטק B2C , המספק ערבויות דיגיטליות בתהליך דיגיטלי מלא'
    //     },
    //     {
    //         companyName: 'Syqe',
    //         img1: syqe,
    //         img2: '',
    //         website: 'https://www.syqe.com/',
    //         description: 'פיתוח CRM ותמיכה בתהליכי אוטומציה למרקטינג וניהול תהליכים מול לקוחות המותג'
    //     },
    //     {
    //         companyName: 'אור ביטוחים',
    //         img1: or,
    //         img2: '',
    //         website: 'https://or-ins.co.il/',
    //         description: 'פיתוח מערכת דיגיטלית למוצרי ביטוח - רכב, משכנתא, נסיעות לחו״ל. '
    //     },
    //     {
    //         companyName: 'יותר קל',
    //         img1: asal,
    //         img2: '',
    //         website: '',
    //         description: 'פיתוח מערכת אינטרנטית לגיוס וניהול עובדים זרים בישראל'
    //     },
    //     {
    //         companyName: 'הסטודיו הדיגיטלי ',
    //         img1: digital,
    //         img2: '',
    //         website: 'https://digitalstudio.co.il/',
    //         description: 'פיתוח מערכת קורסים לסטודיו למשחק של גל אמיתי'
    //     },
    //     {
    //         companyName: 'WeCheck',
    //         img1: wecheck,
    //         img2: '',
    //         website: 'https://wecheck.news/',
    //         description: 'פלטפורמה דיגיטלית לבדיקת כיסוי צקים ובדיקות רקע למשכירי דירות'
    //     },
    //     {
    //         companyName: 'משק מלר',
    //         img1: meller,
    //         img2: '',
    //         website: 'https://meshekmeller.co.il/',
    //         description: 'חנות דיגיטלית לרכישת פירות, ירקות ומוצרי מזווה'
    //     },
    //     {
    //         companyName: 'Zoom REI',
    //         img1: zoom,
    //         img2: '',
    //         website: 'https://www.zoom-rei.com/',
    //         description: 'הקמת מערכת מידע לניהול נכסים ונדלן'
    //     },
    //     {
    //         companyName: 'השטיח המעופף ',
    //         img1: carpet,
    //         img2: '',
    //         website: 'https://www.flying.co.il/',
    //         description: 'פיתוח מערכת פנימית לניהול הזמנות'
    //     },
    //     {
    //         companyName: 'שנקר',
    //         img1: shenkar,
    //         img2: '',
    //         website: 'https://www.myaguda.co.il/',
    //         description: 'פיתוח אתר אגודת הסטודנטים של שנקר'
    //     },
    //     {
    //         companyName: 'אגודת הסטודנטים קרית אונו ',
    //         img1: kiryat,
    //         img2: '',
    //         website: 'https://onostudent.co.il/',
    //         description: 'פיתוח אתר אגודת הסטודנטים של קרית אונו'
    //     },
    //     {
    //         companyName: 'הבינתחומי הרצליה',
    //         img1: idc,
    //         img2: '',
    //         website: 'https://www.runi.ac.il/',
    //         description: 'פיתוח אתר אגודת הסטודנטים של מכללת הבינתחומי'
    //     },
    //     {
    //         companyName: 'Canzon',
    //         img1: canzon,
    //         img2: '',
    //         website: 'https://www.canzon.com/',
    //         description: 'פיתוח חנות אינטרנטית למותג CBD עולמי'
    //     },
    //     {
    //         companyName: 'By Morano',
    //         img1: mornao,
    //         img2: '',
    //         website: 'https://bymorano.com/',
    //         description: 'פיתוח חנות אינטרנטית למותג אופנה ישראלי'
    //     },
    //     {
    //         companyName: 'Pro-Center',
    //         img1: proCenter,
    //         img2: '',
    //         website: 'https://procenter.co.il/',
    //         description: 'פיתוח חנות אינטרנטית למותג טיפוח ושיער ישראלי'
    //     },
    //     {
    //         companyName: 'Heel-Free',
    //         img1: heel,
    //         img2: '',
    //         website: 'https://heel-free.com/',
    //         description: 'פיתוח חנות אינטרנטית למותג מדרסים ישראלי'
    //     },
    //     {
    //         companyName: 'Sea time ',
    //         img1: sea,
    //         img2: '',
    //         website: 'https://sea-time.co.il/',
    //         description: 'פיתוח אתר אינטרנט להזמנת יאכטות במגוון יעדים בעולם '
    //     },
    //     {
    //         companyName: 'Fitnuts',
    //         img1: fitnuts,
    //         img2: '',
    //         website: 'https://fitnuts.com/',
    //         description: 'פיתוח פלטפורמה דיגיטלית לאימוני ספורט ותזונה'
    //     },
    //         {
    //             companyName: 'Holon municipality',
    //                 img1: Holon,
    //             img2: '',
    //             website: 'https://www.holon.muni.il/Pages/default.aspx',
    //             description: 'Development of the Holon municipality website',
    //             category: "",
    //         },
    //         {
    //             companyName: 'Bezalel Are Academy',
    //                 img1: Bezalel,
    //             img2: '',
    //             website:  "https://www.bezalel.ac.il/",
    //             description: 'Development of the Bezalel Art Academy website',
    //             category: "",
    //         },
    //         {
    //             companyName: 'Blue giraffe',
    //                 img1: Giraffe,
    //             img2: '',
    //             website: 'https://www.bluegiraffe.co.il/',
    //             description: "CRM development and support in automation processes for marketing and process management with the brand\'s",
    //             category: "",
    //         },
    //         {
    //             companyName: 'Rosh-haayin municipality',
    //                 img1: Rosh,
    //             img2: '',
    //             website: 'https://www.rosh-haayin.muni.il/',
    //             description: 'CRM development and support in automation processes',
    //             category: "",
    //         },
    //
    //
    // ],
    ru: [
        {
            companyName: 'Holon municipality',
            img1: Holon,
            img2: '',
            website: 'https://www.holon.muni.il/Pages/default.aspx',
            description: 'Разработка веб-сайта муниципалитета Холона',
            category: "",
        },
        {
            companyName: 'Bezalel Are Academy',
            img1: Bezalel,
            img2: '',
            website:  "https://www.bezalel.ac.il/",
            description: 'Разработка веб-сайта Bezalel Art Academy',
            category: "",
        },
        {
            companyName: 'Blue giraffe',
            img1: Giraffe,
            img2: '',
            website: 'https://www.bluegiraffe.co.il/',
            description: "Разработка CRM и поддержка процессов автоматизации маркетинга и управления процессами с брендом",
            category: "",
        },
        {
            companyName: 'Rosh-haayin municipality',
            img1: Rosh,
            img2: '',
            website: 'https://www.rosh-haayin.muni.il/',
            description: 'Разработка и поддержка CRM в процессах автоматизации',
            category: "",
        },
        {
            companyName: 'Eco99FM',
            img1: eco99,
            img2: eco99,
            website: 'https://eco99fm.maariv.co.il/',
            description: 'Интернет, мобильное, автомобильное и телевизионное приложения для израильской радиостанции',
            category: "brand",
        },
        {
            companyName: 'Keshet12',
            img1: keshet,
            img2: keshetBw,
            website: 'https://www.mako.co.il/tv',
            description: 'Рекламное приложение для ведущего телеканала Израиля.',
            category: "brand",

        },
        {
            companyName: 'Quik',
            img1: quik,
            img2: quikCoIl,
            website: 'https://quik.co.il/',
            description: 'Разработка интернет-магазина по продаже товаров народного потребления',
            category: "brand",
        },
        {
            companyName: 'OBLI',
            img1: obli,
            img2: '',
            website: 'https://obli.co.il/',
            description: 'Финтех-стартап B2C, который предоставляет цифровые гарантии с полностью цифровым процессом.',
            category: "startup",
        },
        {
            companyName: 'Syqe',
            img1: syqe,
            img2: '',
            website: 'https://www.syqe.com/',
            description: 'Разработка CRM и поддержка процессов автоматизации маркетинга и управления процессами с клиентами бренда',
            category: "startup",
        },
        {
            companyName: 'OR Insurance',
            img1: or,
            img2: '',
            website: 'https://or-ins.co.il/',
            description: 'Разработка цифровой системы для страховых продуктов - авто, ипотека, путешествия.',
            category: "brand",
        },
        {
            companyName: 'ASAL',
            img1: asal,
            img2: '',
            website: '',
            description: 'Разработка интернет-системы для найма и управления иностранными работниками в Израиле',
            category: "startup",
        },
        {
            companyName: 'Digital studio ',
            img1: digital,
            img2: '',
            website: 'https://digitalstudio.co.il/',
            description: 'Разработка системы курсов для студии актерского мастерства',
            category: "brand",
        },
        {
            companyName: 'WeCheck',
            img1: wecheck,
            img2: '',
            website: 'https://wecheck.news/',
            description: 'Цифровая платформа для проверки охвата чеками и проверки биографических данных арендаторов квартир',
            category: "startup",
        },
        {
            companyName: 'Meshek Meller',
            img1: meller,
            img2: '',
            website: 'https://meshekmeller.co.il/',
            description: 'Цифровой магазин для покупки фруктов, овощей и кладовых продуктов',
            category: "brand",
        },
        {
            companyName: 'Zoom REI',
            img1: zoom,
            img2: '',
            website: 'https://www.zoom-rei.com/',
            description: 'Создание информационной системы по управлению имуществом и недвижимостью',
            category: "brand",
        },
        {
            companyName: 'Flying Carpet ',
            img1: carpet,
            img2: '',
            website: 'https://www.flying.co.il/',
            description: 'Разработка внутренней системы управления заказами',
            category: "brand",
        },
        {
            companyName: 'Shenkar',
            img1: shenkar,
            img2: '',
            website: 'https://www.myaguda.co.il/',
            description: 'Разработка сайта студенческой ассоциации «Шенкар»',
            category: "brand",
        },
        {
            companyName: 'Kiryat Ono Student Association',
            img1: kiryat,
            img2: '',
            website: 'https://onostudent.co.il/',
            description: 'Разработка веб-сайта Студенческой ассоциации Кирьят-Оно',
            category: "brand",
        },
        {
            companyName: 'IDC',
            img1: idc,
            img2: '',
            website: 'https://www.runi.ac.il/',
            description: 'Разработка сайта студенческой ассоциации IDC',
            category: "brand",
        },
        {
            companyName: 'Canzon',
            img1: canzon,
            img2: '',
            website: 'https://www.canzon.com/',
            description: 'Разработка интернет-магазина для мирового бренда CBD',
            category: "startup",
        },
        {
            companyName: 'By Morano',
            img1: mornao,
            img2: '',
            website: 'https://bymorano.com/',
            description: '',
            category: "startup",
        },
        {
            companyName: 'Pro-Center',
            img1: proCenter,
            img2: '',
            website: 'https://procenter.co.il/',
            description: 'Development of an online store for an Israeli care and hair brand',
            category: "startup",
        },
        {
            companyName: 'Heel-Free',
            img1: heel,
            img2: '',
            website: 'https://heel-free.com/',
            description: 'Разработка интернет-магазина для израильского фэшн бренда'
        },
        {
            companyName: 'Sea time ',
            img1: sea,
            img2: '',
            website: 'https://sea-time.co.il/',
            description: 'Разработка сайта для заказа яхт в различных локациях по всему миру'
        },
        {
            companyName: 'Fitnuts',
            img1: fitnuts,
            img2: '',
            website: 'https://fitnuts.com/',
            description: 'Разработка цифровой платформы для тренировок по спорту и питанию',
            category: "startup",
        },

    ],
};
