import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {HeadlineP} from "../components/Fonts.Style";
import useWindowSize from "../hooks/useWindowSize";
import ButtonMain from "../components/Buttons/ButtonMain";
import TimeIcon from "../assets/img/time.svg"
import ErrorIcon from "../assets/img/error_outline.svg"
import {HireMain} from "../styles/components/hire";
import ButtonFormSubmit from "../components/Buttons/ButtonFormSubmit";
import {Link, useParams} from "react-router-dom";
import {hireFormOptions} from "../data/hireFormOptions";
import DoneIcon from "../assets/img/doneIcon.svg";
import {useFormik} from "formik";
import * as yup from "yup";
import {TextField} from "@material-ui/core";
// import useDirection from "../hooks/useDirection";

const Hire = () => {

    const {i18n, t} = useTranslation();
    const {lang} = useParams();
    const renderOptions = lang && lang === "he" ? hireFormOptions.he : (hireFormOptions[i18n.language.substring(0, 2)] || hireFormOptions.en);
    const screenSize = useWindowSize().width;
    const [step, setStep] = useState(null);
    const [isStepValid, setIsStepValid] = useState(false);
    const [showError, setShowError] = useState(false);
    const [showDoneMessage, setShowDoneMessage] = useState(false);
    const [isRtl, setIsRtl] = useState(false);

    const hireSchema =  yup.object({
        lastName: yup
            .string()
            .required(),
        firstName: yup
            .string()
            .required(),
        email: yup
            .string()
            .required(),
        mobile: yup
            .string()
            .required(),
        companySize: yup
            .string()
            .required(),
        industry: yup
            .string()
            .required(),
        position: yup
            .string()
            .required(),
        technology: yup
            .string()
            .required(),
        isBudgetGreater3K: yup
            .string()
            .required(),
        howSoon: yup
            .string()
            .required(),
        howLong: yup
            .string()
            .required(),
        engagementLevel: yup
            .string()
            .required(),
        remotelyFriendly: yup
            .string()
            .required(),
    })

    const handleSubmit = async (values = null) => {
        if (step === 10 && HireFormik.isValid) {
            try {
                const response = await fetch('https://hook.eu1.make.com/s47r47mbcxfxpmx18yh3zj4lp6d4xyow', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(values),
                });
                if (!response.ok) {
                    console.log(`Error sending webhook, response not ok1`, response);
                    return;
                }
                console.log("Form submited", values, response)
                setShowDoneMessage(true);
            } catch (error) {
                console.log(`Error sending webhook: ${error.message}`);
            }
            return
        }
        isStepValid ? setStep(step+1) : setShowError(true);
    }

    const HireFormik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            mobile: '',
            companySize: '',
            industry: '',
            position: '',
            technology: '',
            isBudgetGreater3K: '',
            howSoon: '',
            howLong: '',
            engagementLevel: '',
            remotelyFriendly: ''
        },
        validationSchema: hireSchema,
        onSubmit: (values) => {
            handleSubmit(values);
        }
    });

    const handleFieldChange = (field, val, isMultiple = false) => {
        if (step !== 10)  {
            setIsStepValid(true);
        }
        if (isMultiple) {
            let currentVals = HireFormik.values[field] ? HireFormik.values[field].split('; ') : [];
            if (currentVals.indexOf(val) > -1) {
                currentVals = currentVals.filter(item => item !== val);
            } else {
                currentVals.push(val)
            }
            currentVals = currentVals.join('; ')
            HireFormik.setFieldValue(field, currentVals);
            return;
        }
        HireFormik.setFieldValue(field, val);
    }

    useEffect(() => {
        if (isStepValid) {
            setShowError(false);
        }
    }, [isStepValid]);

    useEffect(() => window.scrollTo(0, 0), []);

    useEffect(() => {
        setIsStepValid(false);
    }, [step])

    useEffect(() => {
        if (step === 10 ) setIsStepValid(HireFormik.isValid);
    }, [HireFormik, step])

    useEffect(() => {

        if (lang) {
            i18n.changeLanguage(lang);
        }

        return () => {
            if (i18n.language === "he") {
                i18n.changeLanguage("en");
            }
        }
    }, [])

    useEffect(() => {
        setIsRtl(i18n.dir() === "rtl");
        document.getElementById("HireProductive").style.direction = i18n.dir();
    }, [i18n.language]);

    return (
        <HireMain isRtl={isRtl} onSubmit={HireFormik.handleSubmit} name={"HireProductive"} id={"HireProductive"} className={"HireProductive"}>
            {
                !step ? <div className="no-step">
                    <HeadlineP style={{
                        margin: screenSize > 500 ? '10rem auto 4rem' : '7rem auto 2rem',
                        textAlign: 'center'
                    }}>
                        {t("hire_heading")}
                    </HeadlineP>
                    <div className="top">
                        <h2>{t("hire.heading")} <span>{t("hire.headingSpan")}</span></h2>
                        <p>{t("hire.subHeading")}</p>
                        <div className="btnWrap" onClick={() => setStep(1)}>
                            <ButtonMain title={t("btn.get_started")}
                                        width={screenSize > 500 ? "400px" : "unset"}
                                        maxWidth="230px"
                                        marginLeft="auto"
                                        marginRight="auto"
                                        minHeight={"48px"}
                                        upperCase={true}
                                        notBold={false}
                                        fontSize={'18px'}
                            />
                        </div>
                        <p className="time">
                            <img src={TimeIcon} alt=""/>
                            {t("hire.takes_time")}
                        </p>
                    </div>
                    <div className="bottom">
                        <div className="content">
                            <p>{t("hire.conditions1")}</p>
                            <p>{t("hire.conditions2")}</p>
                        </div>
                    </div>
                </div> : <div className={"steps"}>
                    <div className="step">
                        <div className="step-name">
                            <span className="step-number">{step}</span>
                            {t(`hire.stepNames.${step}`)}
                        </div>
                        {
                            step === 10 ?
                                <div>
                                    <TextField
                                        name="firstName"
                                        id="firstName"
                                        value={HireFormik.values.firstName}
                                        onChange={HireFormik.handleChange}
                                        required
                                        fullWidth
                                        placeholder={t("hire.placeholders.firstName")}
                                    />
                                    <TextField
                                        name="lastName"
                                        id="lastName"
                                        value={HireFormik.values.lastName}
                                        onChange={HireFormik.handleChange}
                                        required
                                        fullWidth
                                        placeholder={t("hire.placeholders.lastName")}
                                    />
                                    <TextField
                                        name="email"
                                        id="email"
                                        value={HireFormik.values.email}
                                        onChange={HireFormik.handleChange}
                                        type="email"
                                        required
                                        fullWidth
                                        placeholder={t("hire.placeholders.email")}
                                    />
                                    <TextField
                                        name="mobile"
                                        id="mobile"
                                        value={HireFormik.values.mobile}
                                        onChange={HireFormik.handleChange}
                                        type="tel"
                                        required
                                        fullWidth
                                        placeholder={t("hire.placeholders.mobile")}
                                    />

                                    <TextField
                                        className={"hidden"}
                                        name="companySize"
                                        id="companySize"
                                        value={HireFormik.values.companySize}
                                        onChange={HireFormik.handleChange}
                                        required
                                    />
                                    <TextField
                                        className={"hidden"}
                                        name="position"
                                        id="position"
                                        value={HireFormik.values.position}
                                        onChange={HireFormik.handleChange}
                                        required
                                    />
                                    <TextField
                                        className={"hidden"}
                                        name="industry"
                                        id="industry"
                                        value={HireFormik.values.industry}
                                        onChange={HireFormik.handleChange}
                                        required
                                    />
                                    <TextField
                                        className={"hidden"}
                                        name="technology"
                                        id="technology"
                                        value={HireFormik.values.technology}
                                        onChange={HireFormik.handleChange}
                                        required
                                    />
                                    <TextField
                                        className={"hidden"}
                                        name="howLong"
                                        id="howLong"
                                        value={HireFormik.values.howLong}
                                        onChange={HireFormik.handleChange}
                                        required
                                    />
                                    <TextField
                                        className={"hidden"}
                                        name="howSoon"
                                        id="howSoon"
                                        value={HireFormik.values.howSoon}
                                        onChange={HireFormik.handleChange}
                                        required
                                    />
                                    <TextField
                                        className={"hidden"}
                                        name="isBudgetGreater3K"
                                        id="isBudgetGreater3K"
                                        value={HireFormik.values.isBudgetGreater3K}
                                        onChange={HireFormik.handleChange}
                                        required
                                    />
                                    <TextField
                                        className={"hidden"}
                                        name="engagementLevel"
                                        id="engagementLevel"
                                        value={HireFormik.values.engagementLevel}
                                        onChange={HireFormik.handleChange}
                                        required
                                    />
                                    <TextField
                                        className={"hidden"}
                                        name="remotelyFriendly"
                                        id="remotelyFriendly"
                                        value={HireFormik.values.remotelyFriendly}
                                        onChange={HireFormik.handleChange}
                                        required
                                    />
                                </div> :
                                <div className="options">
                                    {
                                        renderOptions[step].isMultiple ? <p className="multiple">{t("hire.multiple")}</p> : null
                                    }
                                    {
                                        renderOptions[step] && renderOptions[step].options.map(option => {
                                            return (
                                                <div key={renderOptions[step].key + option}
                                                    className={HireFormik.values[renderOptions[step].key]?.includes(option) ? "option active" : "option"}
                                                    onClick={() => handleFieldChange(renderOptions[step].key, option, renderOptions[step].isMultiple)}
                                                >
                                                    <span className="radio"/>
                                                    {option}
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                        }
                        {(showError && step !== 10) ? <p className={"error"}>
                            <img src={ErrorIcon} alt=""/>
                            {t("hire.error")}
                        </p> : null}
                    </div>
                    <span onClick={() => step !== 10 ? handleSubmit() : null}>
                        <ButtonFormSubmit page={"home"} text={step === 10 ? t("btn.send") : t("btn.next")} disabled={false} type={step !== 10 ? "button" : "submit"}/>
                    </span>
                    {showDoneMessage ? <div className={"doneWrap"}>
                        <div className="doneContent">
                            <div className="top"><img src={DoneIcon} alt=""/></div>
                            <h2 className={"doneHeading"}>{t("hire.doneHeading")}</h2>
                            <p className={"doneSubHeading"}>{t("hire.doneSubHeading")}</p>
                            <p className={"doneText"}>{t("hire.doneText")}</p>
                            <Link to={"/"}><ButtonMain title={t("btn.home")} /></Link>
                        </div>
                    </div> : null}
                </div>
            }
        </HireMain>
    )
}

export default Hire

