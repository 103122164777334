import React from 'react';
import styled from 'styled-components/macro';

import facebook from '../../assets/img/facebook.svg';
import instagram from '../../assets/img/instagram.svg';
import linkedin from '../../assets/img/linkedin.svg';
import FooterContent from './FooterContent';
import { footerData } from '../../data/footerData';
import useWindowSize from "../../hooks/useWindowSize";
import FooterMobileContent from "./FooterMobileContent";
import {useTranslation} from "react-i18next";

const FooterStyled = styled.footer`
	display: flex;
	background-color: #210846;
	color: var(--white);
	padding: 115px 85px 205px;
	justify-content: space-between;

	.logo-wrap {
		display: flex;
		justify-content: flex-start;
		margin-right: 7.8%;
		display: flex;
		width: fit-content;
		img {
			//width: 13rem;
			height: 30px;
			object-fit: cover;
		}
	}
	
	.content-wrap {
		flex: 1;
		display: flex;
		justify-content: flex-start;
		max-width: 1100px;
	}
	
	.social-icons {
		display: flex;

		img {
			width: 28px;
			height: 28px;
			margin-right: 16px;
		}
	}

	.contact-info {
		min-width: 300px;
		> * {
			font-weight: bold;
			font-size: 1.2rem;
			line-height: 120%;
			letter-spacing: -0.02em;
			color: var(--white);
			margin-bottom: 2.5rem;
			text-decoration: none;
		}
	}

	@media screen and (max-width: 960px) {
		padding: 24px;
		flex-direction: column;
		.log-wrap {
			width: 100%;
		}
		.contact-info {
			flex: 1
		}
		.logo-wrap img {
			height: 22px;
		}
	}
`;

const AppFooter = () => {
	const screenSize = useWindowSize().width;
	const {i18n} = useTranslation();
	const renderFooterData = footerData[i18n.language.substring(0,2)] || footerData.en;
	return (
		<FooterStyled id="footer">
			<div className="logo-wrap">
				<img src={screenSize > 960 ? renderFooterData.logo : renderFooterData.mobileLogo} alt="" />
			</div>
			{screenSize > 960 ? (
				<div className="content-wrap">
					<div className="contact-info flex column">
						<p>{renderFooterData.contactDetails.address}</p>
						<a href={`tel:${renderFooterData.contactDetails.phoneNumber}`}>{renderFooterData.contactDetails.phoneNumber}</a>
						{/*<a href={`mailto:${renderFooterData.contactDetails.email}`}>{renderFooterData.contactDetails.email}</a>*/}
						<a href={`mailto:idan@productive.co.il`}>{renderFooterData.contactDetails.email}</a>
						<div className="social-icons">
							<a href={renderFooterData.contactDetails.facebook} target="_blank" rel="noreferrer">
								<img src={facebook} alt="" />
							</a>
							<a href={renderFooterData.contactDetails.instagram} target="_blank" rel="noreferrer">
								<img src={instagram} alt="" />
							</a>
							<a href={renderFooterData.contactDetails.linkedin} target="_blank" rel="noreferrer">
								<img src={linkedin} alt="" />
							</a>
						</div>
					</div>
					<FooterContent />
				</div>
			) : (<FooterMobileContent />)}

		</FooterStyled>
	);
};

export default AppFooter;
