import RoadMap from "../assets/img/InformationSystem/sub/Monday/roadmap.svg";


export const informationSystemServiceCards = {
    en: [
        {
            img: RoadMap,
            title: "implementation CRM system",
            description: "Implementation of advanced systems according to the nature of the current activity. We work with the world's leading brands and customize the TOP for you. The service includes consulting, characterization, assimilation and support."
        },
        {
            img: RoadMap,
            title: "Automations and integrations",
            description: "Implementation of automation tools and integrations, so that you can advance to the peak of technology, benefit from time savings, optimize business processes and wonderfully improve the work environment."
        },
        {
            img: RoadMap,
            title: "Technical support and ongoing support",
            description: "Technical support for all the products and systems that have been implemented, or alternatively dedicated support for companies and organizations that have not used our implementation services, but are interested in receiving support and accompaniment services for any malfunction."
        },
        {
            img: RoadMap,
            title: "Training services",
            description: "Trainings for organizations to manage the work environment and customers, with the aim of getting the most out of the implemented systems. The trainings are given individually or to departments in the organization."
        },
    ],
    // he: [
    //     {
    //         img: RoadMap,
    //         title: "implementation CRM system",
    //         description: "Implementation of advanced systems according to the nature of the current activity. We work with the world's leading brands and customize the TOP for you. The service includes consulting, characterization, assimilation and support."
    //     },
    //     {
    //         img: RoadMap,
    //         title: "Automations and integrations",
    //         description: "Implementation of automation tools and integrations, so that you can advance to the peak of technology, benefit from time savings, optimize business processes and wonderfully improve the work environment."
    //     },
    //     {
    //         img: RoadMap,
    //         title: "Technical support and ongoing support",
    //         description: "Technical support for all the products and systems that have been implemented, or alternatively dedicated support for companies and organizations that have not used our implementation services, but are interested in receiving support and accompaniment services for any malfunction."
    //     },
    //     {
    //         img: RoadMap,
    //         title: "Training services",
    //         description: "Trainings for organizations to manage the work environment and customers, with the aim of getting the most out of the implemented systems. The trainings are given individually or to departments in the organization."
    //     },
    // ],
    ru: [
        {
            img: RoadMap,
            title: "Внедрение CRM системы",
            description: "Внедрение передовых систем в соответствии с характером текущей деятельности. Мы работаем с ведущими мировыми брендами и подбираем для Вас лучшее. В услугу входит консультация, характеристика, освоение и поддержка."
        },
        {
            img: RoadMap,
            title: "Автоматизация и интеграция",
            description: "Внедрение инструментов автоматизации и интеграции, чтобы Вы могли достичь вершины технологий, сэкономить время, оптимизировать бизнес-процессы и значительно улучшить рабочую среду."
        },
        {
            img: RoadMap,
            title: "Техническая и постоянная поддержка",
            description: "Техническая поддержка для всех внедренных продуктов и систем, или, альтернативно, специализированная поддержка для компаний и организаций, которые не пользовались нашими услугами по внедрению, но заинтересованы в получении услуг поддержки и сопровождения при любых неисправностях."
        },
        {
            img: RoadMap,
            title: "Услуги по обучению",
            description: "Тренинги для организаций по управлению рабочей средой и клиентами с целью получения максимальной отдачи от внедренных систем. Тренинги проводятся индивидуально или для подразделений организации."
        },
    ]
 };
