import planningIcon from "../assets/img/planningIcon.svg";
import designIcon from "../assets/img/designIcon.svg";
import developIcon from "../assets/img/developIcon.svg";
import growthIcon from "../assets/img/growthIcon.svg";

export const process = {
    en: [
        {
            name: 'Planning',
            title: 'Project Planning',
            text: 'The design phase focuses on the needs of the users and the ability to enter the market quickly. We’ll understand the requirements and goals of the project, conduct market research and select the appropriate technologies.',
            image: '',
            icon: planningIcon,
        },
        {
            name: 'Design',
            title: 'UX & UI Design',
            text: 'Aesthetic and strategic design, which draws users in and produces conversions.\n' +
                'Get out of the process with a complete design guide, which will dictate the visual language of the product. We will design the product along the same guidelines, along with insights from user research, and leading UX/UI principles.',
            image: '',
            icon: designIcon,
        },
        {
            name: 'Development',
            title: 'Software Development',
            text: 'We will develop while maintaining high standards, performance and information security.\n' +
                'We will select the server and resource architecture according to the requirements collected, with an emphasis on optimization and maximum availability.',
            image: '',
            icon: developIcon,
        },
        {
            name: 'Growth',
            title: 'Growth Analysis',
            text: 'We will develop while maintaining high standards, performance and information security.\n' +
                'We will select the server and resource architecture according to the requirements collected, with an emphasis on optimization and maximum availability.',
            image: '',
            icon: growthIcon,
        },
    ],
    // he: [
    //     {
    //         name: 'Planning',
    //         title: 'Project Planning',
    //         text: 'craft and refine your ideas for your digital productcraft and refine your ideas for your digital productcraft and refine your ideas for your digital product',
    //         image: '',
    //         icon: planningIcon,
    //     },
    //     {
    //         name: 'Design',
    //         title: 'App Designing',
    //         text: 'craft and refine your ideas for your digital productcraft and refine your ideas for your digital productcraft and refine your ideas for your digital product',
    //         image: '',
    //         icon: designIcon,
    //     },
    //     {
    //         name: 'Develop',
    //         title: 'Software Development',
    //         text: 'craft and refine your ideas for your digital productcraft and refine your ideas for your digital productcraft and refine your ideas for your digital product',
    //         image: '',
    //         icon: developIcon,
    //     },
    //     {
    //         name: 'Growth',
    //         title: 'Business Growth',
    //         text: 'craft and refine your ideas for your digital productcraft and refine your ideas for your digital productcraft and refine your ideas for your digital product',
    //         image: '',
    //         icon: growthIcon,
    //     },
    // ],
    ru: [
        {
            name: 'План',
            title: 'Планирование Проекта',
            text: 'Этап проектирования фокусируется на потребностях пользователей и возможности быстрого выхода на рынок. Разберемся с требованиями и целями проекта, проведем исследование рынка и подберем подходящие технологии.',
            image: '',
            icon: planningIcon,
        },
        {
            name: 'Дизайн',
            title: 'UX & UI Дизайн',
            text: 'По окончанию этого этапа Вы получите полное руководство по дизайну, которое будет диктовать визуальный язык продукта. Мы разработаем дизайн продукта в соответствии со всеми пожеланиями клиента, а также с учетом результатов исследований пользователей и основных принципов UX / UI.',
            image: '',
            icon: designIcon,
        },
        {
            name: 'Разработка',
            title: 'Разработка ПО',
            text: 'Мы разработаем серверную часть, сохраняя при этом высокие стандарты, производительность и информационную безопасность. Мы разработаем клиентскую часть в соответствии с техническими особенностями, требованиями проекта и рекомендациями по проектированию.',
            image: '',
            icon: developIcon,
        },
        {
            name: 'Рост',
            title: 'Аналитика роста',
            text: 'Мы анализируем и улучшаем все процессы во время разработки, а также после запуска проекта. Мы сопровождаем большинство наших клиентов даже после завершения первоначального проекта: вносим различные коррективы в работе бизнеса в соответствии с потребностями рынка, разрабатываем следующие шаги развития продукта и многое другое.',
            image: '',
            icon: growthIcon,
        },
    ]
}
