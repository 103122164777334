import Learning from "../assets/img/careers/learning.svg";
import Clients from "../assets/img/careers/clients.svg";
import Agility from "../assets/img/careers/agility.svg";
import Innovation from "../assets/img/careers/innovation.svg";
import Internship from "../assets/img/careers/internship.svg";
import Autonomy from "../assets/img/careers/authonomy.svg";
import Community from "../assets/img/careers/community.svg";
import International from "../assets/img/careers/international.svg";

export const careersCards = {
    en: [
        {
            img: Learning,
            title: "Constant learning",
            description: "We take advantage of every day to learn and get better. Whether to improve ourselves or the performance of our products."
        },
        {
            img: Clients,
            title: "The employee is in the center",
            description: "We believe in people, take care to develop and maintain individual and group quality"
        },
        {
            img: Agility,
            title: "Agility",
            description: "We adapt quickly to market changes."
        },
        {
            img: Innovation,
            title: "Innovation",
            description: "We work with the most innovative technologies, and develop creative solutions for our clients."
        },
        {
            img: Internship,
            title: "Internship program",
            description: "An intensive three-month program that takes newbies from the course to practice."
        },
        {
            img: Autonomy,
            title: "Autonomy",
            description: "Our developers are not code monkeys. They are involved in making decisions for the product they are developing."
        },
        {
            img: Community,
            title: "Community and cultural pattern",
            description: "Happy hour, fun days and enrichment activities."
        },

        {
            img: International,
            title: "International staff",
            description: "Increase your network of contacts and work with talented people from all over the world."
        }
    ],
    // he: [
    //     {
    //         img: Learning,
    //         title: "למידה מתמדת",
    //         description: "אנו מנצלים כל יום כדי ללמוד ולהשתפר. עובדים קשה לשפר את עצמנו או את הביצועים של המוצרים שלנו."
    //     },
    //     {
    //         img: Clients,
    //         title: "העובד נמצא במרכז",
    //         description: "אנחנו מאמינים באנשים, דואגים להתפתח ולשמור על איכות אישית וקבוצתית."
    //     },
    //     {
    //         img: Agility,
    //         title: "זריזות",
    //         description: "אנו מסתגלים במהירות לשינויים בשוק."
    //     },
    //     {
    //         img: Innovation,
    //         title: "חדשנות",
    //         description: "אנו עובדים עם הטכנולוגיות החדשניות ביותר, ומפתחים פתרונות יצירתיים עבור לקוחותינו"
    //     },
    //     {
    //         img: Internship,
    //         title: "תוכנית התמחות",
    //         description: "תוכנית אינטנסיבית של שלושה חודשים שלוקחת מתחילים מהקורס לעבודה בתעשייה."
    //     },
    //     {
    //         img: Autonomy,
    //         title: "אוטונומיה",
    //         description: "המפתחים שלנו אינם code monkeys. הם מעורבים בקבלת החלטות לגבי המוצר שהם מפתחים."
    //     },
    //     {
    //         img: Community,
    //         title: "דפוס קהילתי ותרבותי",
    //         description: "ימי כיף, פעילויות העשרה ו Happy hour."
    //     },
    //
    //     {
    //         img: International,
    //         title: "צוות בינלאומי",
    //         description: "הגדל את רשת הקשרים שלך ועבוד עם אנשים מוכשרים מכל העולם."
    //     }
    // ],
    ru: [
        {
            img: Learning,
            title: "Постоянное обучение",
            description: "Мы учимся каждый день, чтобы становиться лучше. Чтобы улучшить себя и производительность наших продуктов."
        },
        {
            img: Clients,
            title: "Клиент всегда в центре внимания",
            description: "Мы являемся полноправными партнерами в достижении успеха наших клиентов, а не только поставщиками программного обеспечения."
        },
        {
            img: Agility,
            title: "Гибкость",
            description: "Мы быстро адаптируемся к изменениям рынка."
        },
        {
            img: Innovation,
            title: "Инновации",
            description: "Мы работаем с самыми инновационными технологиями и разрабатываем креативные решения для наших клиентов."
        },
        {
            img: Internship,
            title: "Интернатура",
            description: "Интенсивная трехмесячная программа, которая позволяет выпускникам курсов стать полноценными разработчиками."
        },
        {
            img: Autonomy,
            title: "Автономия",
            description: "Наши разработчики это не 'обезьяны-кодеры'. Они участвуют в принятии решений по продукту, который они разрабатывают."
        },
        {
            img: Community,
            title: "Корпоративная составляющая",
            description: "Незабываемые корпоративы и развивающие мероприятия"
        },

        {
            img: International,
            title: "Международный персонал",
            description: "Расширьте свою сеть контактов и работайте с талантливыми людьми со всего мира."
        }
    ]
};
