import Monday from "../../assets/img/InformationSystem/sub/Monday/img.svg";
import Salesforce from "../../assets/img/InformationSystem/sub/Saleforce/img.svg";
import TopAppServices from "../../assets/img/InformationSystem/top_apps_services.svg";
import Data from '../../assets/img/InformationSystem/sections/aboutus/data.svg';

export const en = {
    translation: {
        "hero_title":
            {
                0: "We’re building digital <highlight>solutions</highlight> for your next business <highlight>challenge</highlight>",
                1: "We will <highlight>scale up</highlight> your team for your next development <highlight>projects</highlight>",
                2: "We’re believing <highlight>productive </highlight> work for innovative eat tech <highlight>products</highlight>",
            },
        "our_work": "Our Work",
        "about_us_headline": "About us",
        "our_process": "Our Process",
        "meet_the_team": "Meet the team",
        "testimonials": "Testimonials",
        "team_slogan": "The People who make things happen",
        "team_part_slogan": "Want to become a part of the team?",
        "technologies": "Technologies",
        "benefits": "Benefits",
        "our_offices": "Our offices",
        "lets_build": "Lets build a",
        "description": "Description",
        "our_friends": "Our friends",
        "scroll_more": "scroll to see more",
        "see_more": "see more",
        "hire_heading": "Hire",
        "hire": {
            "heading": "Hire",
            "headingSpan": "Developers",
            "subHeading": "Hire an expert, remote developer fast",
            "conditions1": "By completing this form you agree",
            "conditions2": "to our Terms of Use, Privacy & Cookies Policies",
            "takes_time": "Takes 4 minutes",
            "error": "Please make a selection",
            "doneHeading": "Thank you!",
            "doneSubHeading": "We will get back to you soon",
            "doneText": "We’re looking forward to matching you with the experts your company needs.",
            "multiple": "Choose as many as you like",
            "stepNames": {
                1: "How many persons are currently working in your company?",
                2: "What industry does your company belong to?",
                3: "What kind of team member(s) do you need?",
                4: "What kind of technologies does your company work with?",
                5: "Does your hiring budget for this team member exceed 3000 USD?",
                6: "How soon do you need a team member?",
                7: "How long do you need the developer?",
                8: "What level of engagement will you require from the marketer?",
                9: "Are you comfortable working with someone remotely?",
                10: "Some last details about you",
            },
            "placeholders": {
                "firstName": "First Name",
                "lastName": "Last Name",
                "email": "Email",
                "mobile": "Phone Number",
            }
        },
        "progress": {
            1: "Welcome",
            2: "Services",
            3: "Portfolio",
            4: "Process",
            5: "Testimonials",
            6: "Team",
            7: "Contacts"
        },
        "nav": {
            "get_in_touch": "Get In Touch With Us",
            "build": "Services",
            "services": "Services",
            "portfolio": "Portfolio",
            "team": "The team",
            "blog": "Blog",
            "careers": "Careers",
            "about": "About us",
            "friends": "Clients ",
            "news": "News",
            "crm": "CRM"
        },
        "btn": {
            "read_more": "Read More",
            "learn_more": "Learn More",
            "read_article": "Read The Article",
            "read_less": "Read Less",
            "see_portfolio": "See Portfolio",
            "visit_site": "Visit Site",
            "see_full_team": "See Full team",
            "get_free_quote": "Get free quote",
            "send": "send",
            "see_openings": "Check our open positions",
            "see_careers": "See All Careers",
            "project_in_progress": "Project is in progress",
            "more_info": "More info",
            "see-full-customers": "See Full Customers",
            "contact-expert": "Contact Expert",
            "get_started": "Get started",
            "next": "next",
            "home": "Home Page"
        },
        "build": {
            "title": "Our services",
            "product": {
                "title": "Build a Product",
                "description":
                    "<p>We build software products from A to Z.</p>" +
                    "<p>We use the most advanced project management methodologies, such as Agile and Scrum. </p>" +
                    "<p>In the process, we monitor every glitch and problem.</p>",
                "mobileDescription":
                    "<p>We build software products from A to Z. We use the most advanced project management methodologies, such as Agile and Scrum. In the process, we monitor every glitch and problem, measure what is important, and perform ongoing optimization.</p>" +
                    "<p>Our work processes allow us to enter the market quickly - compared to a traditional development process. In every project we take, it is important for us to meet deadlines and goals - and bring every product to the end point</p>" +
                    "<p><title>Application development</title> All the needs of the project in under one roof: planning, design, software development, feasibility study and entering the market. We have developed a wide range of software products: complex websites, CRM systems, applications, management systems and information. Try us.</p>" +
                    "<p><title>MVP/POC development</title> Outsource development allows you to skip the barriers to innovation that exist in large organizations. We will open up the initial concept and examine feasibility. Next, we will pass you the baton to take the venture to the next level.</p>",
                "servicesTitle": "Looking to grow your product and scale up?",
                "servicesDescription": "We build software products from A to Z. We use the most advanced project management methodologies, such as Agile and Scrum. In the process, we monitor every glitch and problem, measure what is important, and perform ongoing optimization. "
            },
            "team": {
                "title": "Build a Team",
                "description":
                    "<p><title>Need a programmer for yesterday?</title>We can arrange it.</p>" +
                    "<p>Outsourced programmers are a great solution for startups or small businesses that need quality manpower quickly.</p>",
                "mobileDescription":
                    "<p><title>Need a programmer for yesterday?</title>We can arrange it. Outsourced programmers are a great solution for startups or small businesses that need quality manpower quickly.</p>" +
                    "<p>We allow maximum flexibility: recruit and train a programmer for your team within 7-14 days on average, depending on the scope you need.</p>" +
                    "<p>Working with us allows you to have the confidence and stability that can not be found in platforms like Upwork and Toptal. Our programmers are salaried employees. They come to our offices every day, receive equipment, social benefit, professional guidance (and even yoga classes). In today's competitive market, we know how to retain talent with us for years. Most of our clients work with the same programmers for 3-4 years. They become an integral part of their team.</p>" +
                    "<p>Outsourced programmers can be recruited in two ways:</p>" +
                    "<p>1. Under our professional management - with a project manager.</p>" +
                    "<p>2. Under your professional management - work directly with the programmer.</p>",
                "servicesTitle": "Looking to grow your team and scale up?",
                "servicesDescription": "Need a dynamic team? you should talk with us. we’ll be glad to discuss your needs with you & help you build a team of top talent to help your business reach its potential."
            }
        },
        "services": {
            "team": "Team",
            "product": "Product"
        },
        "about_us": {
            "title": "Our Story",
            "main_text": "<p>Productive's story begins with a great love for technology.\n" +
                "Just as a sculptor loves to sculpt, as a poet loves to write songs -\n" +
                "We love creating technological solutions that make people's lives better.\n</p>" +
                "<p>Productive's story begins with a great love for technology.\n" +
                "Just as a sculptor loves to sculpt, as a poet loves to write songs -\n" +
                "We love creating technological solutions that make people's lives better.\n</p>" +
                "<p>It is important for us to be full partners in the success of our clients. \n" +
                "We take ownership and treat each project as if it were our product. Our standards are high, we make sure to meet goals and bring every product to the end point.</p>" +
                "<p>The set of values, manpower and work methods allow us to break forward quickly. From a small office at the beginning, to an office that employs dozens of employees, and works with leading companies in the economy, such as Keshet 12, Reichman University, Shenkar, Bank Hapoalim, Bank Discount and more.</p>" +
                "<p>We continue to grow, and our mission is to develop software products that will have a positive impact on the lives of millions of people.\n</p>",
            "banner": {
                "title": "<h1>40+ <br> Companies</h1>",
                "items": {
                    "item0": "User friendly modern & intuitive",
                    "item1": "New features, trendy design.",
                    "item2": "End to end, high-level development",
                    "item3": "24/7 customer services",
                }
            },
            "secondary_text": "Need a dynamic team? you should talk with us. we’ll be glad to discuss your needs with you & help you build a team of top talent to help your business reach its potential."
        },
        "contact_form": {
            "headline": "<div>we’re <span>here </span>for you and we’re ready to <span> work </span> with you<p/>",
            "mob_headline": "Contact us!",
            "discuss_needs": "Lets discuss your needs!",
            "productive_together": "Let’s be productive together",
        },
        "placeholders": {
            "name": "Full name",
            "phone": "Phone Number",
            "email": "Email Address",
            "message": "Personal Note",
            "Link": "Portfolio Link"
        },
        "careers": {
            "title": "Careers",
            "heading": "We're the steppingstone to the next level in your career.",
            "main_text": "We are looking for someone who Wants to reach the big break in their career, to influence and make an impact with what they do, and loves to explore and learn new technologies. Someone who wants to work in a global team and knows how to solve problems and withstand pressure."
        },
        "positions": {
            "heading": "Available Positions ",
            "see_position": "See position",
            "position_description": "Position description",
            "requirements": "Requirements"
        },
        "friendsCategories" : {
            "brands": "Brands",
            "startups": "Startups"
        },
        "blog": {
            "heading": "THE BLOG",
            "title": "Productive Blog",
        },
        "news": {
            "heading": "from the press",
            "title": "This is what the press thinks of us",
        },
        "InformationSystem": {
            "heading": "Information System",
            "title": "Implementation Of An Information System In Business",
            "description": "<p>CRM implementation services are aimed at providing businesses with platform-based or custom CRM solutions to optimize sales, marketing, and customer service processes.</p>" +
                "<p>With 8 years of experience in CRM services, ScienceSoft carries out CRM implementation to help companies reduce the costs of lead acquisition and conversion, as well as customer retention costs.</p>",
            "img": TopAppServices,
            "sections": {
                "services": {
                    "heading": "Our Services",
                },
                "products": {
                    "heading": "SalesForce products",
                },
                "about-us": {
                    "heading": "About Us",
                    "title": "We are partners in your technological %challenges% and %optimize% the work environment",
                    "description": "<p>As Consulting partner, Productive creates a unique strategy for your organization that enables CRM as a catalyst to reach your transformational goals.</p>" +
                        "<p>We know the technology, the strategy and how it applies to industries. With an eye on innovation, Productive brings in technical expertise early, leveraging years of experience to design, architect and implement a Salesforce strategy that meets your organization’s needs today and builds a plan for the future.</p>",
                    "img": Data
                },
                "customers": {
                    "heading": "our customers",
                    "subtitle": "official website",
                }
            },
        },
        "monday": {
            "heading": "MONDAY.COM",
            "title": "Join %Monday.Com% System Management Revolution",
            "description": "<p>We have the experience and resources to help your organization maximize the value of your monday.com investment. Our team has helped clients with all aspects of using monday.com including onboarding, it consulting, training, and project-based implementation services.</p>",
            "img": Monday,
        },
        "salesforce": {
            "heading": "Salesforce",
            "title": "Join %Salesforce% System Management Revolution",
            "description": "<p>As Salesforce Consulting partner, Productive creates a unique strategy for your organization that enables Salesforce as a catalyst to reach your transformational goals.</p>" +
                "<p>We know Salesforce – the technology, the strategy and how it applies to industries. With an eye on innovation, Productive brings in technical expertise early, leveraging years of experience to design, architect and implement a Salesforce strategy that meets your organization’s needs today and builds a plan for the future.</p>",
            "img": Salesforce,
        }
    }
}
