import React, {useEffect, useState} from 'react';
import styled from "styled-components";

import ContactForm from '../ContactForm/ContactForm';
import { clients } from '../../data/clientsData';
import { GreyHeadline } from '../Fonts.Style';
import {useTranslation} from "react-i18next";
import useWindowSize from "../../hooks/useWindowSize";
import Slider from "react-slick";
import useDirection from "../../hooks/useDirection";

const ClientsStyled = styled.section`
  text-align: center;
  margin: 11rem auto 5rem;
  width: 100%;
  max-width: 1340px;
  padding: 0 30px;

  .clients-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 37px;
    grid-row-gap: 108px;
    margin-bottom: 10rem;

    .clients-box {
      height: 220px;
      background: var(--white);
      border: 2px solid #C4C4C4;
      border-radius: 12px;
      flex-direction: column;
      position: relative;

      p {
        margin-top: 1rem;
        font-size: 18px;
        font-weight: bold;
      }

      .img-wrapper {
        border-radius: 50%;
        width: 140px;
        height: 140px;
        background: var(--grey5);
        transition: background-color .5s;
        overflow: hidden;

        justify-content: center;

        img {
          width: 100px;
          object-fit: contain;
        }
      }
    }
  }

  .animation.clients-box {
    padding: 85px 30px 30px;

    .img-wrapper {
      transform: translate(-50%, -50%);
      left: 50%;
      position: absolute;
      top: 0;
      background: white;
      border: 1px solid #9146ff;
      box-shadow: 2px 2px 10px rgba(0,0,0,.3);
    }

    .animation-content {
      display: flex;
      flex-direction: column;
      justify-content: center;

      p {
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;
        max-height: 66px;
        overflow: hidden;
        color: var(--black);
      }

      .progress {
        background-color: #9ab3b3;
        border-radius: 6px;
        padding: 5px;
      }

      a {
        font-weight: bold;
        font-size: 1.125rem;
        line-height: 128%;
        text-decoration-line: underline;
        text-transform: capitalize;
        color: var(--purple1);
        margin-top: 15px;
      }
    }
  }
  
  .clientsMobile {
    margin-bottom: 150px;
    .category {
      .categoryHeading {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 800;
        font-size: 28px;
        line-height: 120%;
        letter-spacing: -0.02em;
        color: #9146FF;
        text-align: left;
        
      }
      &:nth-child(2) {
        .categoryHeading {
          text-align: right;
        }
      }
      .mobItem {
        width: 140px !important;
        margin: 0 8px;
        aspect-ratio: 14 / 9;
        background: #FFFFFF;
        border: 2px solid #C4C4C4;
        border-radius: 13.2352px;
        padding: 20px;
        a {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          img {
            width: 100%;
            height: auto;
            max-height: 100%;
          }
        }
      }
    }
    .slick-slider {
      margin: 30px -30px 35px;
    }
  }

  @media screen and (max-width: 960px) {
    .clients-grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
    }
  }
  @media screen and (max-width: 768px) {
    margin: 10rem auto 5rem;
    > p {
      display: none;
    }
    
    .contac-form {
      padding: 0;
    }

    .clients-grid {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(1, 1fr);
      grid-row-gap: 80px;
    }
  }

  @media screen and (max-width: 768px) {
    margin-top: 7rem;
  }
`;

const Clients = () => {

    const [animationIndex, setAnimationIndex] = useState(null);
    const {t, i18n} = useTranslation();
    const isRtl = useDirection();
    const renderClients = clients[i18n.language.substring(0,2)] || clients.en;
    const screenSize = useWindowSize().width;

    useEffect(() => {
        window.scrollTo(0, 0);
        document.getElementById("root").style.overflowX = "hidden";
        return () => document.getElementById("root").style.overflowX = "unset";
    }, []);

    const settings = {
        centerMode: true,
        variableWidth: true,
        autoplay: true,
        speed: 500,
        autoplaySpeed: 2000,
    };

    const settings2 = {
        centerMode: true,
        variableWidth: true,
        autoplay: true,
        speed: 500,
        autoplaySpeed: 2000,
        rtl: true
    };

    return (
        <ClientsStyled>
            <GreyHeadline style={{ margin: '44px auto 124px' }}>
                {t("our_friends")}
            </GreyHeadline>
            {screenSize > 500
                ?  <div className={"clients-grid"}>
                    {renderClients.map((client, index) => (
                        <div
                            className={animationIndex === index ? "animation clients-box flex align-center justify-center" : "clients-box flex align-center justify-center"}
                            key={index}
                            onMouseLeave={() => setAnimationIndex(null)}
                            onMouseEnter={() => setAnimationIndex(index)}
                        >
                            <div className="img-wrapper flex">
                                <img src={client.img1} alt={client.companyName} className='flex' />
                            </div>
                            {animationIndex !== index && <p>{client.companyName}</p>}
                            {animationIndex === index && (
                                <div className="animation-content">
                                    <p >{client.description}</p>
                                    {client.website !== '' ? <a href={`${client.website}`} target="_blank" rel="noreferrer">
                                        {t("btn.visit_site")}
                                    </a> : <p className={"progress"}>{t('btn.project_in_progress')}</p>}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
                : <div className={"clientsMobile"}>
                    <div className="category">
                        <h3 className={'categoryHeading'}>{t('friendsCategories.brands')}</h3>
                        <Slider {...settings}>
                            {renderClients.filter(c => c.category === "brand").map((item, i) => (
                                <div className={"mobItem"} key={i}>
                                    <a href={`${item.website}`} target="_blank" rel="noreferrer">
                                        <img src={item.img1} alt={item.companyName} className='flex' />
                                    </a>
                                </div>
                            ))}
                        </Slider>
                    </div>
                    <div className="category">
                        <h3 className={'categoryHeading'}>{t('friendsCategories.startups')}</h3>
                        <Slider {...settings2}>
                            {renderClients.filter(c => c.category === "startup").map((item, i) => (
                                <div className={"mobItem"} key={i}>
                                    <a href={`${item.website}`} target="_blank" rel="noreferrer">
                                        <img src={item.img1} alt={item.companyName} className='flex' />
                                    </a>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            }

            <ContactForm />
        </ClientsStyled >
    );
};

export default Clients;
