import React from "react";
import styled from "styled-components";

import Form from "./Form";
import useWindowSize from "../../hooks/useWindowSize";
import { Trans, useTranslation } from "react-i18next";

const ContactFormStyled = styled.div`
  width: 100%;
  max-width: 1320px;
  justify-content: space-between;
  padding: 0 30px;
  //padding: ${(props) => (props.page === "home" ? "0 30px" : "0")};
  margin: 185px auto 185px;

  .form-headline {
    align-items: flex-end;
    margin-top: -20px;
    width: 52%;
    .headline-wrapper {
      width: 100%;
      font-weight: bold;
      font-size: 3vw;
      line-height: 140%;
      text-transform: uppercase;
      color: var(--grey1);
      margin-bottom: 2.5rem;
      text-align: left;
      span {
        color: var(--purple1);
      }
    }
    .headline-p {
      font-weight: bold;
      font-size: 1.5rem;
      line-height: 1.7rem;
      text-transform: uppercase;
      text-align: left;
      width: 100%;
      color: #bfbfbf;
    }
  }

  .mob-headline {
    font-weight: bold;
    font-size: 58px;
    line-height: 140%;
    color: #7a7a7a;
    width: 100%;
    text-align: center;
  }

  @media (max-width: 960px) {
    flex-direction: column;
    .form-headline {
      width: 100%;
    }

    margin: 0 auto 50px;
  }

  @media screen and (max-width: 500px) {
    .form-headline .headline-p {
      font-size: 16px;
      text-align: center;
      width: 100%;
      justify-content: center;
    }
  }
`;

const ContactForm = ({ page }) => {
  const screenSize = useWindowSize().width;
  const { t } = useTranslation();

  return (
    <ContactFormStyled id="contact" className="flex contac-form" page={page}>
      <div className="flex form-headline column">
        {screenSize > 500 && (
          <Trans
            components={{
              div: <div className="headline-wrapper" />,
              span: <span />,
            }}
          >
            contact_form.headline
          </Trans>
        )}
        <div className="flex headline-p">
          {t(
            screenSize > 500
              ? "contact_form.discuss_needs"
              : "contact_form.productive_together"
          )}
        </div>
        {screenSize < 500 && (
          <p className="mob-headline">{t("contact_form.mob_headline")}</p>
        )}
      </div>
      <Form page="home" />
    </ContactFormStyled>
  );
};

export default ContactForm;
