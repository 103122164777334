import eco99 from '../assets/img/eco99.png';
import eco99L from '../assets/img/eco99left.png';
import logo99 from '../assets/img/99-logo.svg';
import obliLogo from '../assets/img/obli-logo.svg';
import obliMob from '../assets/img/obliMob.png';
import screenShot from '../assets/img/Screen Shot.svg';
import getin from '../assets/img/getin.png';
import getinL from '../assets/img/getinLeft.png';
import getinLogo from '../assets/img/getinLogo.svg';
import digitalLogo from '../assets/img/digitalstudioLogo.png';
import digital from '../assets/img/digital.png';
import mobiti from '../assets/img/mobiti.png';
import finiti from '../assets/img/finiti.png';
import quick from '../assets/img/quick.png';

export const companies = {
    en: [
        {
            companyName: 'Obli',
            img: screenShot,
            mobileImg: obliMob,
            logo: obliLogo,
            website: 'https://obli.co.il/',
            projectTypes: ['Plan', 'Design', 'Development', 'Growth', 'QA'],
            mobileTypes: "Fintech, Start-up, Mobile, Desktop",
            description: "<p>We went through all the stages of a startup together: from initial idea, to MVP, to product, to recruitment.</p><p>From a company of 2 people - to a company of 20 people that today is worth more than NIS 50 million.</p><p>From a process that takes two weeks at the bank and costs money, we have created a simple digital process that takes 5-7 minutes.</p>",
            portfolio: "<p><span class='title'>The challenge</span>When we met the founder, he came up with an idea - to reinvent the guarantee market. He told us about the inefficiency in this area: the need to imprison money in a bank in a complicated bureaucratic process that takes two weeks. He wanted to produce a simple, digital solution that would only take a few minutes.</p>" +
                "<p>The main challenges in the development process were the connection between technology, users and regulation.</p>" +
                "<p><span class='title'>The process</span>We started with the initial characterization of the product. We then developed MVPs.\n" +
                "We have made changes following the feedback from users, and have adapted ourselves to the regulatory requirements that change every few months.\n</p>" +
                "<p>Along the way, we adhered to a lean development process to bring a product to market quickly and slow down the rate of cash burning. (For example, we raised capital with MVP, before there was one client, following proof of regulatory feasibility.)</p>" +
                "<p>We built advanced systems and integration with the Bank of Israel, we received the most stringent security standard.</p>",
            testimonial: {
                "name": "Dani Ben Ner",
                "position": "CEO OBLI",
                "text": "The dedicated care, availability and immediate response, providing the advanced solutions and especially the unceasing creativity are what make your success our success!"
            }
        },
        {
            companyName: 'Eco99fm',
            img: eco99,
            imgL: eco99L,
            mobileImg: eco99,
            logo: logo99,
            website: 'https://eco99fm.maariv.co.il/',
            projectTypes: ['Plan', 'Design', 'Development'],
            mobileTypes: "Brand, Mobile, Desktop",
            description: "<p>We built an innovative design and a modern user experience. Something reminiscent of an overseas software product experience.</p><p>Throughout the project, we balanced between producing a product that looks international, and the uniqueness of Radio 99. (The live broadcasts and the people behind the radio like Tal and Aviad.)</p>",
            portfolio: "<p><span class='title'>The challenge</span>Radio 99 has realized that the market is changing, and from classic radio to on-demand audio (AOD).\n" +
                "They wanted to produce an Israeli alternative for companies like Spotify, Apple Music and other players.\n" +
                "(Significant challenge - to compete with such large companies, with a relatively small team)\n" +
                "Therefore, they approached us to produce a high level platform in terms of product and user experience.\n</p>" +
                "<p><span class='title'>The process</span>We built an innovative design and a modern user experience. Something reminiscent of an overseas software product experience.\n" +
                "Throughout the project, we balanced between producing a product that looks international, and the uniqueness of Radio 99. (The live broadcasts and the people behind the radio like Tal and Aviad.)\n</p>" +
                "<p>Scrum and Agile methodologies have helped us make sure we put out a product with real feasibility in the market. Following the MVP we created, we discovered that the main feature we were thinking of developing - streaming, was irrelevant. And we decided to concentrate on the radio. The move helped Radio 99 save money on unnecessary development - and hit the market faster.\n</p>" +
                "<p><span class='title'>The results</span>We are proud that we were able to produce a product at a very high level - in relation to the development budget. The project has over a million downloads in the app store, and a lot of revenue from advertisements.</p>" +
                "<p>We continue to accompany Radio 99 in the ongoing development and construction of additional features to this day.</p>",
            testimonial: {
                "name": "Hagit Kamin",
                "position": "CEO Radio 99",
                "text": "Lovely company! Professional people, courteous service and amazing flexibility in providing solutions. Happy to work with Idan the project manager - and highly recommend"
            }
        },
        {
            companyName: 'Digital studio',
            img: digital,
            mobileImg: digital,
            logo: digitalLogo,
            website: 'https://digitalstudio.co.il/',
            projectTypes: ['Plan', 'Design', 'Development'],
            description: "<p>We started with initial characterization, defined initial screens and information flow architecture. We built an MVP, and from it we learned how to improve the user experience and conversion rates:</p><p>We created shorter lessons, trailers, an experience lesson and a personal connection with the actors.</p><p>Together with the studio staff we continue to upgrade and improve the system and serve hundreds of students.</p>",
            portfolio: "<p><span class='title'>The challenge</span>When the \"digital studio\" came to us - they were a physical school for acting. The Corona period hit them hard, and they were forced to shut down physical activity following the guidelines.\n" +
                "They wanted to find a digital solution that would allow them to continue teaching acting even remotely.\n</p>" +
                "<p><span class='title'>The process</span>We started with initial characterization, defined initial screens and information flow architecture.\n" +
                "We built an MVP, and from it we learned how to improve the user experience and conversion rates:\n" +
                "We created shorter lessons, trailers, an experience lesson and a personal connection with the actors.\n" +
                "Together with the studio staff we continue to upgrade and improve the system and serve hundreds of students.\n</p>" +
                "<p><span class='title'>The results</span>The project was successful above and beyond expectations, and continues to exist in its own right even after the corona restrictions. The digital studio manages to reach users outside the central region or in Israel in general, or for those who prefer to study digitally. The studio's community grows by dozens of subscribers each month.</p>" +
                "<p>We continue to lend to the digital studio to this day, improve conversion rates, develop new features, and adapt to the market.</p>"
        },
        {
            companyName: 'Get-in',
            img: getin,
            imgL: getinL,
            mobileImg: getin,
            logo: getinLogo,
            website: 'https://get-in.com/',
            projectTypes: ['Plan', 'Design', 'Development'],
            description: "<p>Get It is not a leading application in the field of events. The company hired us to enter the American and international market, and produce a system that can support a large number of users.</p><p>Get In gave us a challenge that many software houses would refuse - to enter existing infrastructure and upgrade it. (Entering existing infrastructure is often more challenging than creating more than 0)</p>",
            portfolio: "<p><span class='title'>The challenge</span>Get It is not a leading application in the field of events. The company hired us to enter the American and international market, and produce a system that can support a large number of users.\n" +
                "Get In gave us a challenge that many software houses would refuse - to get into existing infrastructure and upgrade it. (Entering existing infrastructure is often more challenging than creating more than 0)\n</p>" +
                "<p><span class='title'>Main points</span>We performed a process of refactoring (rebuilding the software, along with preserving some of the existing stuff)\n" +
                "<ul><li>-We have built an infrastructure that can handle millions of users simultaneously.</li>" +
                "<li>-We made the system multilingual.</li>" +
                "<li>-We have built an infrastructure that supports receiving payments from all over the world.</li>" +
                "<li>-We have created new features and optimization that supports user retention.</li></ul></p>" +
                "<p>We continue to lend divorce today, and develop new features.</p>",
            testimonial: {
                "name": "Niv Ariel",
                "position": "CEO Get-In",
                "text": "The wonderful responses I get are many and varied and they all indicate the quality and professionalism of the work. The site you have built matches all the expectations I have set and even beyond"
            }
        },
        {
            companyName: 'Quik',
            img: quick,
            mobileImg: quick,
            logo: '',
            website: 'https://quik.co.il/',
            projectTypes: [],
            description: "",
            portfolio: "<p><span class='title'>The challenge</span>Quick are a quick solution for an online supermarket. They contacted us to increase the development team in a fast and quality manner.\n" +
                "(The internal recruitment process is long and tedious. More than that, there is a lot of competition for quality candidates.)\n</p>" +
                "<p><span class='title'>The process</span>We have a particularly fast and effective recruitment process.\n" +
                "We team up with our developer companies, or recruit specifically for them.\n</p>" +
                "<p>We quickly find suitable candidates and train them. They can join teams within a week or two and the process begins.</p>" +
                "<p>The workforce sits in our offices, under our professional management and technological standards. The workers are employed as employees with all the social rights, and receive from us a full envelope of support.</p>" +
                "<p>This process makes it possible to recruit an external workforce that integrates as an internal team for everything, and continues to work with the company for years to come.</p>" +
                "<p>We have been working with Quick for 3 years with 4 developers we have recruited.</p>"
        },
        {
            companyName: 'Mobitti',
            img: mobiti,
            mobileImg: mobiti,
            logo: '',
            website: 'https://mobitti.net/',
            projectTypes: [],
            description: "",
            portfolio: "<p><span class='title'>The challenge</span>Mobiti is a company that offers a technological solution to higher education institutions and organizations. They contacted us to increase the development team in a fast and quality manner.\n" +
                "The internal recruitment process is long and tedious. More than that, there is a lot of competition for quality candidates.\n</p>" +
                "<p><span class='title'>The process</span>We have a particularly fast and effective recruitment process.\n" +
                "We team up with our developer companies, or recruit specifically for them.\n" +
                "We quickly find suitable candidates and train them. They can join teams within a week or two and the process begins.\n</p>" +
                "<p>The workforce sits in our offices, under our professional management and technological standards. The workers are employed as employees with all the social rights, and receive from us a full envelope of support.</p>" +
                "<p>This process makes it possible to recruit an external workforce that integrates as an internal team for everything, and continues to work with the company for years to come.</p>" +
                "<p>We have been working with Mobiti for about a year, with 3 developers we have recruited.</p>"
        },
        {
            companyName: 'Finity X',
            img: finiti,
            mobileImg: finiti,
            logo: '',
            website: 'https://finityx.com/',
            projectTypes: [],
            description: "",
            portfolio: "<p><span class='title'>The challenge</span>Finity X is a startup that solves problems for investors in the capital market, using artificial intelligence.\n" +
                "When they came to us, they were looking for a technology partner to build more products with.\n</p>" +
                "<p><span class='title'>Technology</span>The company collects information from the network, and puts it into its models. According to the models, decisions are made about the performance of a particular stock.</p>" +
                "<p><span class='title'>The process</span>We built the front end for the company, and also created a series of products for different markets:\n" +
                "First, a product for banks and insurance companies: a store with lots of strategies, which allows the customer of the bank or insurance company to choose a strategy, download it and use it. (Similar to the Netflix screen)\n" +
                "Second, a technological solution directly to the end customer.\n</p>" +
                "<p>Today, we continue to develop with Finity X more products.</p>"
        },
    ],
    // he: [
    //     {
    //         companyName: 'Obli',
    //         img: screenShot,
    //         mobileImg: obliMob,
    //         logo: obliLogo,
    //         website: 'https://obli.co.il/',
    //         projectTypes: ['Plan', 'Design', 'Development', 'Growth', 'QA'],
    //         mobileTypes: "Fintech, Start-up, Mobile, Desktop",
    //         description: "<p>עברנו ביחד את כל השלבים של סטארטאפ: מרעיון ראשוני, ל MVP, למוצר, לגיוס.</p><p>מחברה של 2 אנשים - לחברה של 20 איש ששווה היום יותר מ 50 מיליון ש\"ח.</p><p>מתהליך שלוקח שבועיים בבנק ועולה כסף, יצרנו תהליך דיגיטלי ופשוט שלוקח 5-7 דקות.</p>",
    //         portfolio: "<p><span class='title'>האתגר</span>כשפגשנו את המייסד, הוא הגיע עם רעיון - להמציא מחדש את שוק הערבות. הוא סיפר לנו על חוסר היעילות בתחום הזה: הצורך לכלוא כסף בבנק בתהליך בירוקרטי ומסובך שלוקח שבועיים. הוא רצה לייצר פתרון פשוט ודיגיטלי שלוקח כמה דקות בלבד.</p>" +
    //             "<p>האתגרים המרכזיים בתהליך הפיתוח היו החיבור בין טכנולוגיה, משתמשים ורגולציה.</p>" +
    //             "<p><span class='title'>התהליך</span>התחלנו מהאיפיון הראשוני של המוצר. אחר כך פיתחנו MVPs. עשינו שינויים בעקבות הפידבק ממשתמשים, והתאמנו את עצמנו לדרישות הרגולטוריות שמשתנות כל מס' חודשים.</p>" +
    //             "<p>לאורך הדרך, הקפדנו על תהליך פיתוח רזה כדי להוציא מוצר לשוק במהירות ולבלום את קצב שריפת המזומנים. (למשל, גייסנו הון עם MVP, לפני שהיה לקוח אחד, בעקבות הוכחת היתכנות הרגולטורית.)</p>" +
    //             "<p>בנינו מערכות מתקדמות ואינטגרציה לבנק ישראל, קיבלנו את תקן האבטחה המחמיר ביותר.</p>" +
    //             "<p><span class='title'>התוצאות</span></p>" +
    //             "<p>עברנו ביחד את כל השלבים של סטארטאפ: מרעיון ראשוני, ל MVP, למוצר, לגיוס. מחברה של 2 אנשים - לחברה של 20 איש ששווה היום יותר מ 50 מיליון ש\"ח.</p>" +
    //             "<p>מתהליך שלוקח שבועיים בבנק ועולה כסף, יצרנו תהליך דיגיטלי ופשוט שלוקח 5-7 דקות.</p>" +
    //             "<p>כיום, אובלי מחזיקה מליוני שקלים בערבויות.</p>" +
    //             "<p>אנחנו ממשיכים ללוות את אובלי עד היום כ - CTO חיצוני, להתאים את עצמנו לשינויים ולפתח פיצ'רים נוספים.</p>",
    //         testimonial: {
    //             "name": "דניאל בן נר",
    //             "position": "מנכ״ל OBLI",
    //             "text": "הטיפול המסור, הזמינות והמענה המיידי, מתן הפתרונות המתקדמים ובעיקר היצירתיות הבלתי פוסקת הם שהופכים את ההצלחה שלכם להצלחה שלנו! "
    //         }
    //     },
    //     {
    //         companyName: 'רדיו 99',
    //         img: eco99,
    //         imgL: eco99L,
    //         mobileImg: eco99,
    //         logo: logo99,
    //         website: 'https://eco99fm.maariv.co.il/',
    //         projectTypes: ['Plan', 'Design', 'Development'],
    //         mobileTypes: "Brand, Mobile, Desktop",
    //         description: "<p>בנינו עיצוב חדשני וחווית משתמש מודרנית. משהו שמזכיר חווית של מוצר תוכנה מחו\"ל.</p><p>לאורך הפרויקט, איזנו בין לייצר מוצר שנראה בינלאומי, לבין הייחודיות של רדיו 99. (השידורים החיים והאנשים מאחורי הרדיו כמו טל ואביעד.)</p>",
    //         portfolio: "<p><span class='title'>האתגר</span>רדיו 99 הבינו שהשוק הולך ומשתנה, ומהרדיו הקלאסי עובר לאודיו לפי דרישה (AOD). הם רצו לייצר אלטרנטיבה ישראלית לחברות כמו ספוטיפיי, אפל מיוזיק ושחקנים נוספים. (אתגר משמעותי - להתחרות עם חברות כל כך גדולות, עם צוות קטן יחסית) לכן, הם פנו אלינו כדי לייצר פלטפורמה ברמה גבוהה מבחינת המוצר וחווית המשתמש.</p>" +
    //             "<p><span class='title'>התהליך</span>בנינו עיצוב חדשני וחווית משתמש מודרנית. משהו שמזכיר חווית של מוצר תוכנה מחו\"ל. לאורך הפרויקט, איזנו בין לייצר מוצר שנראה בינלאומי, לבין הייחודיות של רדיו 99. (השידורים החיים והאנשים מאחורי הרדיו כמו טל ואביעד.)</p>" +
    //             "<p>מתודולגיות סקראם ואג'ייל עזרו לנו לוודא שאנחנו מוציאים מוצר עם היתכנות אמיתית בשוק. בעקבות ה MVP שיצרנו, גילינו שהפיצ'ר המרכזי שחשבנו לפתח - סטרימינג, לא רלוונטי. והחלטנו להתרכז ברדיו. המהלך עזר לרדיו 99 לחסוך כסף על פיתוח מיותר - ולצאת לשוק מהר יותר.</p>" +
    //             "<p><span class='title'>התוצאות</span>אנחנו גאים בזה שהצלחנו לייצר מוצר ברמה גבוהה מאוד - ביחס לתקציב הפיתוח. הפרויקט למעל מיליון הורדות בחנות האפליקציות, והכנסות רבות מפרסומות.</p>" +
    //             "<p>אנחנו ממשיכים ללוות את רדיו 99 בפיתוח השוטף ובבנייה של פיצ'רים נוספים עד היום. </p>",
    //         testimonial: {
    //             "name": "חגית קמין",
    //             "position": "מנכ״ל רדיו 99 ",
    //             "text": "חברה מקסימה! אנשים מקצועיים, שירות אדיב וגמישות מדהימה להפליא במתן פתרונות. שמחה לעבוד עם עידן מנהל הפרויקט – וממליצה בחום"
    //         }
    //     },
    //     {
    //         companyName: 'הסטודיו הדיגיטלי',
    //         img: digital,
    //         mobileImg: digital,
    //         logo: digitalLogo,
    //         website: 'https://digitalstudio.co.il/',
    //         projectTypes: [],
    //         description: "<p>התחלנו מאיפיון ראשוני, הגדרנו מסכים ראשונים וארכיטקטורת זרימת מידע. בנינו MVP, ומתוכו למדנו איך לשפר את חווית המשתמש ואחוזי ההמרה:</p><p>יצרנו שיעורים קצרים יותר, טריילרים, שיעור ניסיון וקשר אישי עם השחקנים.</p><p>יחד עם צוות הסטודיו אנחנו ממשיכים לשדרג ולשפר את המערכת ולשרת מאות תלמידים.</p>",
    //         portfolio: "<p><span class='title'>האתגר</span>כש\"הסטודיו הדיגיטלי\" הגיעו אלינו- הם היו בית ספר פיזי למשחק. תקופת הקורונה פגעה בהם קשה, והם נאלצו לסגור את הפעילות הפיזית בעקבות ההנחיות. הם רצו למצוא פתרון דיגיטלי, שיאפשר להם להמשיך וללמד משחק גם מרחוק.</p>" +
    //             "<p><span class='title'>התהליך</span>התחלנו מאיפיון ראשוני, הגדרנו מסכים ראשונים וארכיטקטורת זרימת מידע. בנינו MVP, ומתוכו למדנו איך לשפר את חווית המשתמש ואחוזי ההמרה: יצרנו שיעורים קצרים יותר, טריילרים, שיעור ניסיון וקשר אישי עם השחקנים. יחד עם צוות הסטודיו אנחנו ממשיכים לשדרג ולשפר את המערכת ולשרת מאות תלמידים.</p>" +
    //             "<p><span class='title'>התוצאות</span>הפרוייקט הצליח מעל ומעבר למצופה, וממשיך להתקיים בזכות עצמו גם אחרי הגבלות הקורונה. הסטודיו הדיגיטלי מצליח להגיע למשתמשים מחוץ לאזור המרכז או בישראל בכלל, או למי שמעדיף ללמוד באופן דיגיטלי. הקהילה של הסטודיו גדלה בעשרות מנויים כל חודש.</p>" +
    //             "<p>אנחנו ממשיכים ללוות את הסטודיו הדיגיטלי עד היום, לשפר את אחוזי ההמרה, לפתח פיצ'רים חדשים, ולהתאים את עצמנו לשוק.</p>",
    //     },
    //     {
    //         companyName: 'גט-אין',
    //         img: getin,
    //         imgL: getinL,
    //         mobileImg: getin,
    //         logo: getinLogo,
    //         website: 'https://get-in.com/',
    //         projectTypes: ['Plan', 'Design', 'Development'],
    //         description: "<p>גט אין זו אפליקציה מובילה בתחום האירועים.החברה שכרה אותנו כדי להיכנס לשוק האמריקאי והבינ\"ל, ולייצר  מערכת שיכולה לתמוך בכמות גדולה של משתמשים. </p><p>גט אין נתנו לנו אתגר שהרבה בתי תוכנה היו מסרבים לו - להיכנס לתשתית קיימת ולשדרג אותה. (כניסה  לתשתית קיימת הרבה פעמים מאתגרת יותר מיצירה מ-0)</p>",
    //         portfolio: "<p><span class='title'>האתגר</span>גט אין זו אפליקציה מובילה בתחום האירועים.החברה שכרה אותנו כדי להיכנס לשוק האמריקאי והבינ\"ל, ולייצר מערכת שיכולה לתמוך בכמות גדולה של משתמשים. גט אין נתנו לנו אתגר שהרבה בתי תוכנה היו מסרבים לו - להיכנס לתשתית קיימת ולשדרג אותה. (כניסה לתשתית קיימת הרבה פעמים מאתגרת יותר מיצירה מ-0)</p>" +
    //             "<p><span class='title'>נקודות מרכזיות</span></p>" +
    //             "<p>ביצענו תהליך של ריפקטורינג (בניה מחדש של התוכנה, יחד עם שימור של חלק מהדברים הקיימים)" +
    //             "<ul><li>-בנינו תשתית שמסוגלת להתמודד עם מיליוני משתמשים בו זמנית.</li>" +
    //             "<li>-הפכנו את המערכת לרב לשונית.</li>" +
    //             "<li>-בנינו תשתית שתומכת בקבלת תשלומים מכל העולם.</li>" +
    //             "<li>-יצרנו פיצ'רים חדשים ואופטימיזציה שתומכת בשימור משתמשים.</li></ul></p>" +
    //             "<p>אנחנו ממשיכים ללוות את גט-אין גם היום, ולפתח פיצ'רים חדשים.</p>",
    //         testimonial: {
    //             "name": "ניב אריאל",
    //             "position": "מנכ״ל Get-In",
    //             "text": "התגובות הנפלאות שאני מקבל רבות ומגוונות וכולם מציינים את איכות ומקצועיות העבודה. האתר שבניתם תואם את כל הציפיות שהגדרתי ואף מעבר לכך."
    //         }
    //     },
    //     {
    //         companyName: 'קוויק',
    //         img: quick,
    //         mobileImg: quick,
    //         logo: '',
    //         website: 'https://quik.co.il/',
    //         projectTypes: [],
    //         description: "",
    //         portfolio: "<p><span class='title'>האתגר</span>קוויק הם פתרון מהיר לסופרמרקט אונליין. הם פנו אלינו כדי להגדיל את צוות הפיתוח בצורה מהירה ואיכותית. (תהליך גיוס פנימי הוא ארוך ומייגע. יותר מזה, יש תחרות גדולה על מועמדים איכותיים.)</p>" +
    //             "<p><span class='title'>התהליך</span>יש לנו תהליך גיוס מהיר ואפקטיבי במיוחד. אנחנו מצוותים לחברות מפתחים שלנו, או מגייסים במיוחד בשבילם.</p>" +
    //             "<p>אנחנו מאתרים מועמדים מתאימים במהירות ומעבירים אותם הכשרה. הם יכולים להיכנס לצוותים תוך שבוע או שבועיים מתחיל התהליך.</p>" +
    //             "<p>כוח העבודה יושב במשרדים שלנו, תחת הניהול המקצועי והסטנדרטים הטכנולוגיים שלנו. העובדים מועסקים כשכירים עם כל הזכויות הסוציאלית, ומקבלים מאיתנו מעטפת תמיכה מלאה. </p>" +
    //             "<p>התהליך הזה מאפשר לגייס כוח עבודה חיצוני שמשלב כמו צוות פנימי לכל דבר, וממשיך לעבוד עם החברה שנים קדימה.</p>" +
    //             "<p>אנחנו עובדים עם קוויק כבר 3 שנים עם 4 מפתחים שגייסנו.</p>"
    //     },
    //     {
    //         companyName: 'מוביטי',
    //         img: mobiti,
    //         mobileImg: mobiti,
    //         logo: '',
    //         website: 'https://mobitti.net/',
    //         projectTypes: [],
    //         description: "",
    //         portfolio: "<p><span class='title'>האתגר</span>מוביטי הם חברה שמציעה פתרון טכנולוגי למוסדות השכלה גבוהה וארגונים. הם פנו אלינו כדי להגדיל את צוות הפיתוח בצורה מהירה ואיכותית. תהליך גיוס פנימי הוא ארוך ומייגע. יותר מזה, יש תחרות גדולה על מועמדים איכותיים.</p>" +
    //             "<p><span class='title'>התהליך</span>יש לנו תהליך גיוס מהיר ואפקטיבי במיוחד. אנחנו מצוותים לחברות מפתחים שלנו, או מגייסים במיוחד בשבילם. אנחנו מאתרים מועמדים מתאימים במהירות ומעבירים אותם הכשרה. הם יכולים להיכנס לצוותים תוך שבוע או שבועיים מתחיל התהליך.</p>" +
    //             "<p>כוח העבודה יושב במשרדים שלנו, תחת הניהול המקצועי והסטנדרטים הטכנולוגיים שלנו. העובדים מועסקים כשכירים עם כל הזכויות הסוציאלית, ומקבלים מאיתנו מעטפת תמיכה מלאה.</p>" +
    //             "<p>התהליך הזה מאפשר לגייס כוח עבודה חיצוני שמשלב כמו צוות פנימי לכל דבר, וממשיך לעבוד עם החברה שנים קדימה.</p>" +
    //             "<p>אנחנו עובדים עם מוביטי כשנה, עם 3 מפתחים שגייסנו.</p>"
    //     },
    //     {
    //         companyName: 'פיניטי X',
    //         img: finiti,
    //         mobileImg: finiti,
    //         logo: '',
    //         website: 'https://finityx.com/',
    //         projectTypes: [],
    //         description: "",
    //         portfolio: "<p><span class='title'>האתגר</span>פיניטי X הוא סטארטאפ שפותר בעיות למשקיעים בשוק ההון, באמצעות בינה מלאכותית. כשהם הגיעו אלינו, הם חיפשו שותף טכנולוגי כדי לבנות איתו מוצרים נוספים.</p>" +
    //             "<p><span class='title'>הטכנולוגיה</span>החברה אוספת מידע מהרשת, ומכניסה אותו למודלים שלה. על פי המודלים, מתקבלות החלטות לגבי הביצועים של מניה מסויימת.</p>" +
    //             "<p><span class='title'>התהליך</span>בנינו לחברה את הפרונט-אנד, וגם יצרנו סדרת מוצרים לשווקים שונים: הראשון, מוצר עבור בנקים וחברות ביטוח: חנות עם המון אסטרטגיות, שמאפשרת ללקוח של הבנק או חברת הביטוח לבחור אסטרטגיה, להוריד אותה ולהשתמש בה. (בדומה למסך של נטפליקס) השני, פתרון טכנולוגי ישירות ללקוח הקצה.</p>" +
    //             "<p>כיום, אנחנו ממשיכים לפתח עם פיניטי X מוצרים נוספים.</p>"
    //     },
    // ],
    ru: [
        {
            companyName: 'Obli',
            img: screenShot,
            mobileImg: obliMob,
            logo: obliLogo,
            website: 'https://obli.co.il/',
            projectTypes: ['Планирование', 'Дизайн', 'Разработка', 'Развитие', 'Тестирование'],
            mobileTypes: "Fintech, Start-up, Mobile, Desktop",
            description: "<p>Мы вместе прошли все этапы стартапа: от первоначальной идеи, до MVP, до продукта, до рекрутинга.</p><p>От компании из 2 человек - до компании из 20 человек, которая сегодня стоит более 50 миллионов шекелей.</p><p>Из процесса, который занимает две недели в банке и стоит денег, мы создали простой цифровой процесс, который занимает 5-7 минут.</p>",
            portfolio: "<p><span class='title'>Задача</span>Когда мы встретились с основателем, у него возникла идея — заново изобрести рынок гарантий. Он рассказал нам о неэффективности в этой сфере: необходимости заточить деньги в банке в сложном бюрократическом процессе, который занимает две недели. Он хотел создать простое цифровое решение, которое заняло бы всего несколько минут.</p>" +
                "<p>Основными проблемами в процессе разработки были связи между технологиями, пользователями и регулированием.</p>" +
                "<p><span class='title'>Процесс</span>Мы начали с начальной характеристики продукта. Затем мы разработали MVP.\n" +
                "Мы внесли изменения в соответствии с отзывами пользователей и приспособились к нормативным требованиям, которые меняются каждые несколько месяцев.\n</p>" +
                "<p>Попутно мы придерживались бережливого процесса разработки, чтобы быстро вывести продукт на рынок и снизить скорость сжигания денег. (Например, мы привлекли капитал с помощью MVP до того, как появился один клиент, после подтверждения нормативной осуществимости.)</p>" +
                "<p>Мы построили передовые системы и интеграцию с Банком Израиля, мы получили самый строгий стандарт безопасности.</p>",
            testimonial: {
                "name": "Dani Ben Ner",
                "position": "CEO OBLI",
                "text": "Преданная забота, доступность и немедленная реакция, предоставление передовых решений и особенно непрекращающееся творчество - вот что делает ваш успех нашим успехом!"
            }
        },
        {
            companyName: 'Eco99fm',
            img: eco99,
            imgL: eco99L,
            mobileImg: eco99,
            logo: logo99,
            website: 'https://eco99fm.maariv.co.il/',
            projectTypes: ['Планирование', 'Дизайн', 'Разработка'],
            mobileTypes: "Brand, Mobile, Desktop",
            description: "<p>Мы создали инновационный дизайн и современный пользовательский интерфейс. Что-то напоминающее зарубежный программный продукт.</p><p>На протяжении всего проекта мы балансировали между производством продукта, который выглядит интернациональным, и уникальностью «Радио 99». (Прямые трансляции и люди, стоящие за радио, такие как Таль и Авиад.)</p>",
            portfolio: "<p><span class='title'>Задача</span>Радио 99 осознало, что рынок меняется, и от классического радио к аудио по запросу (AOD).\\n\" +\n" +
                "                 «Они хотели создать израильскую альтернативу для таких компаний, как Spotify, Apple Music и других плееров.\\n» +\n" +
                "                 \"(Серьёзная задача - конкурировать с такими крупными компаниями, с относительно небольшой командой)\\n\" +\n" +
                "                 \"Поэтому они обратились к нам с просьбой создать платформу высокого уровня с точки зрения продукта и взаимодействия с пользователем.\\n</p>" +
                "<p><span class='title'>Процесс</span>Мы создали инновационный дизайн и современный пользовательский интерфейс. Что-то, напоминающее зарубежный программный продукт.\\n\" +\n" +
                "                 «На протяжении всего проекта мы балансировали между производством продукта, который выглядит интернациональным, и уникальностью «Радио 99». (Прямые трансляции и люди, стоящие за радио, такие как Таль и Авиад.)\n</p>" +
                "<p>Методологии Scrum и Agile помогли нам убедиться, что мы выпускаем на рынок продукт с реальной осуществимостью. После того, как мы создали MVP, мы обнаружили, что основная функция, которую мы думали разработать — потоковая передача, не имеет значения. И мы решили сосредоточиться на радио. Этот шаг помог Radio 99 сэкономить деньги на ненужных разработках и быстрее выйти на рынок.</p>" +
                "<p><span class='title'>Результаты</span>Мы гордимся тем, что смогли произвести продукт на очень высоком уровне — по отношению к бюджету разработки. У проекта более миллиона загрузок в магазине приложений и большой доход от рекламы.</p>" +
                "<p>Мы продолжаем сопровождать Радио 99 в постоянной разработке и создании дополнительных функций по сей день.</p>",
            testimonial: {
                "name": "Hagit Kamin",
                "position": "CEO Radio 99",
                "text": "Прекрасная компания! Профессиональные люди, вежливое обслуживание и удивительная гибкость в предоставлении решений. Рад работать с Иданом, руководителем проекта, и очень рекомендую"
            }
        },
        {
            companyName: 'Digital studio',
            img: digital,
            mobileImg: digital,
            logo: digitalLogo,
            website: 'https://digitalstudio.co.il/',
            projectTypes: [],
            description: "Мы начали с начальной характеристики, определили начальные экраны и архитектуру потока информации. Мы создали MVP и узнали, как улучшить взаимодействие с пользователем и коэффициент конверсии:</p><p>мы создали более короткие уроки, трейлеры, урок опыта и личную связь с актерами.</p><p>Вместе с сотрудниками студии мы продолжаем модернизировать и улучшать систему и обслуживать сотни студентов.</p>",
            portfolio: "<p><span class='title'>Задача</span>Когда к нам пришли Digital Studio - это была физшкола актерского мастерства. Период Короны сильно ударил по ним, и они были вынуждены прекратить физическую активность в соответствии с рекомендациями. Они хотели найти цифровое решение, которое позволило бы им продолжать обучать актерскому мастерству даже дистанционно.</p>" +
                "<p><span class='title'>Процесс</span>Мы начали с начальной характеристики, определили начальные экраны и архитектуру потока информации. Мы создали MVP и узнали, как улучшить взаимодействие с пользователем и коэффициент конверсии: Мы создали более короткие уроки, трейлеры, урок опыта и личную связь с актерами. Вместе с сотрудниками студии мы продолжаем модернизировать и улучшать систему и обслуживать сотни студентов.\n</p>" +
                "<p><span class='title'>Результаты</span>Проект оказался успешным вопреки ожиданиям и продолжает существовать сам по себе даже после ограничений, связанных с коронавирусом. Цифровой студии удается охватить пользователей за пределами центрального региона или в Израиле в целом, или для тех, кто предпочитает учиться в цифровом формате. Ежемесячно сообщество студии увеличивается на десятки подписчиков.</p>" +
                "<p>Мы и по сей день продолжаем оддерживать Digital Studio, улучшаем показатели конверсии, разрабатываем новые функции и адаптируемся к рынку.</p>"
        },
        {
            companyName: 'Get-in',
            img: getin,
            imgL: getinL,
            mobileImg: getin,
            logo: getinLogo,
            website: 'https://get-in.com/',
            projectTypes: ['Plan', 'Design', 'Development'],
            description: "<p>Get это не ведущее приложение в области событий. Компания наняла нас, чтобы выйти на американский и международный рынок и создать систему, способную поддерживать большое количество пользователей.</p><p>Get In поставила перед нами задачу, от которой отказались бы многие производители программного обеспечения, — войти в существующую инфраструктуру и обновить его. (Вход в существующую инфраструктуру часто бывает более сложным, чем создание более 0)</p>",
            portfolio: "<p><span class='title'>Задача</span>Получить Это не ведущее приложение в области событий. Компания наняла нас, чтобы выйти на американский и международный рынок и создать систему, способную поддерживать большое количество пользователей.\\n\" +\n" +
                "                 «Get In поставил перед нами задачу, от которой отказались бы многие производители программного обеспечения, — проникнуть в существующую инфраструктуру и обновить ее. (Вход в существующую инфраструктуру часто сложнее, чем создание более чем 0)\\n</p>" +
                "<p><span class='title'>Главные пункты</span>" +
                "<p>Мы провели процесс рефакторинга (пересборка программного обеспечения с сохранением части существующего)\n" +
                "<ul><li>-Мы построили инфраструктуру, которая может одновременно обслуживать миллионы пользователей.</li>" +
                "<li>-Мы сделали систему многоязычной.</li>" +
                "<li>-Мы построили инфраструктуру, поддерживающую прием платежей со всего мира.</li>" +
                "<li>-Мы создали новые функции и оптимизации, которые позволяют удерживать пользователей.</li></ul></p>" +
                "<p>Мы продолжаем поддерживать проект сегодня и разрабатываем новые функции.</p>",
            testimonial: {
                "name": "Niv Ariel",
                "position": "CEO Get-In",
                "text": "Замечательных отзывы, которые я получаю, их много и они разнообразны, и все они говорят о качестве и профессионализме работы. Сайт, который вы создали, соответствует всем моим ожиданиям и даже превосходит их."
            }
        },
        {
            companyName: 'Quik',
            img: quick,
            mobileImg: quick,
            logo: '',
            website: 'https://quik.co.il/',
            projectTypes: [],
            description: "",
            portfolio: "<p><span class='title'>Задача</span>Quick — это быстрое решение для интернет-супермаркета. Они связались с нами, чтобы быстро и качественно увеличить команду разработчиков.\n" +
                "(Процесс внутреннего найма долгий и утомительный. Более того, существует большая конкуренция за качественных кандидатов.)\n</p>" +
                "<p><span class='title'>Процесс</span>У нас особенно быстрый и эффективный процесс найма.\n" +
                "Мы объединяемся с нашими компаниями-разработчиками или набираем людей специально для них.\n</p>" +
                "<p>Мы быстро находим подходящих кандидатов и обучаем их. Они могут присоединиться к командам в течение недели или двух, и процесс начинается.</p>" +
                "<p>Персонал находится в наших офисах под нашим профессиональным управлением и технологическими стандартами. Рабочие нанимаются как служащие со всеми социальными правами и получают от нас полную поддержку.</p>" +
                "<p>Этот процесс позволяет нанимать внешнюю рабочую силу, которая интегрируется как внутренняя команда для всего и продолжает работать с компанией в течение многих лет.</p>" +
                "<p>Мы работаем с Quick в течение 3 лет с 4 разработчиками, которых мы наняли.</p>"
        },
        {
            companyName: 'Mobitti',
            img: mobiti,
            mobileImg: mobiti,
            logo: '',
            website: 'https://mobitti.net/',
            projectTypes: [],
            description: "",
            portfolio: "<p><span class='title'>Задача</span>Mobiti — компания, предлагающая технологическое решение высшим учебным заведениям и организациям. Они связались с нами, чтобы быстро и качественно увеличить команду разработчиков.\n" +
                "Процесс внутреннего найма долгий и утомительный. Более того, существует большая конкуренция за качественных кандидатов.\n</p>" +
                "<p><span class='title'>Процесс</span>У нас особенно быстрый и эффективный процесс найма.\n" +
                "Мы объединяемся с нашими компаниями-разработчиками или набираем специально для них.\n" +
                "Мы быстро находим подходящих кандидатов и обучаем их. Они могут присоединиться к командам в течение недели или двух, и процесс начинается.\n</p>" +
                "<p>Персонал находится в наших офисах под нашим профессиональным управлением и технологическими стандартами. Рабочие нанимаются как служащие со всеми социальными правами и получают от нас полную поддержку.</p>" +
                "<p>Этот процесс позволяет нанимать внешнюю рабочую силу, которая интегрируется как внутренняя команда для всего и продолжает работать с компанией в течение многих лет.</p>" +
                "<p>Мы работаем с Mobiti около года, мы наняли 3 разработчиков.</p>"
        },
        {
            companyName: 'Finity X',
            img: finiti,
            mobileImg: finiti,
            logo: '',
            website: 'https://finityx.com/',
            projectTypes: [],
            description: "",
            portfolio: "<p><span class='title'>Задача</span>Finity X — стартап, который решает проблемы инвесторов на рынке капитала с помощью искусственного интеллекта.\\n\" +\n" +
                "                 «Когда они обратились к нам, они искали технологического партнера, с которым можно было бы создавать больше продуктов.\\n</p>" +
                "<p><span class='title'>Технология</span>Компания собирает информацию из сети и вкладывает ее в свои модели. В соответствии с моделями принимаются решения о производительности той или иной акции.</p>" +
                "<p><span class='title'>Процесс</span>Мы построили интерфейс для компании, а также создали серию продуктов для разных рынков:\n" +
                "Во-первых, продукт для банков и страховых компаний: магазин со множеством стратегий, который позволяет клиенту банка или страховой компании выбрать стратегию, скачать ее и использовать. (Похоже на скрин Netflix)\n" +
                "Во-вторых, технологическое решение непосредственно для конечного потребителя.\n</p>" +
                "<p>Сегодня мы продолжаем разрабатывать больше продуктов вместе с Finity X.</p>"
        },
    ],
};
