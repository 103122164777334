import {Trans, useTranslation} from "react-i18next";
import styled from "styled-components";
import {SectionTitle} from "./index";
import useWindowSize from "../../hooks/useWindowSize";
import React from "react";
import usePartColor from "./hooks/usePartColor";

export default function AboutUs() {
    const { t } = useTranslation();
    const screenSize = useWindowSize().width;

    const AboutUsStyled = styled.div`
      padding-top: 60px;
      padding-bottom: 241px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .content {
        display: flex;
        margin-top: 159px;
        width: 90%;
        .left-container {
          display: flex;
          flex-direction: column;
          max-width: 50%;
          width: 100%;
          
          .title {
            font-weight: 600;
            font-size: 40px;
            line-height: 48px;
            letter-spacing: -0.02em;
            color: #000000;
          }
          
          .desc {
            margin-top: 29px;
            margin-bottom: 68px;
            font-weight: 300;
            font-size: 24px;
            line-height: 34px;
            text-transform: capitalize;
            color: #000000;
            p {
              margin-bottom: 1rem;
            }
          }

          .links {
            display: flex;
            a {
              border: 1.5px solid #9146FF;
              box-shadow: 0px 0px 10px rgba(145, 70, 255, 0.25);
              border-radius: 40px;
              padding: 19px 0;
              text-align: center;
              width: 280px;
              font-weight: 700;
              font-size: 20px;
              line-height: 23px;
              text-transform: uppercase;
              color: var(--purple1);
              background-color: #FFFFFF;
              transition-duration: .25s;
              :hover {
                background-color: var(--purple1);
                color: #FFFFFF;
              }
            }
            .monday-link {
              margin-right: 50px;
            }
          }
        }

        .right-container {
          width: 50%;
          display: flex;
          justify-content: flex-end;
          align-items: flex-start;
        }
      }
      @media screen and (max-width: 960px) {
        .content {
          margin-top: 84px;

          .left-container {
            max-width: 100%;
            .title {
              font-size: 32px;
              line-height: 44px;
            }
            .desc {
              font-size: 16px;
              font-weight: 300;
              line-height: 22px;
              letter-spacing: 0em;
              margin-bottom: 29px;
            }
          }
        }
      }
      @media screen and (max-width: 769px) {
        padding-bottom: 60px;
        .content {
          margin-top: 34px;
          .left-container {
            max-width: 100%;
            .links {
              justify-content: space-around;
              a {
                padding: 10px 0;
                font-size: 16px;
                width: 200px;
              }
              .monday-link {
                margin-right: 0;
              }
            }
          }
        }
      }
      @media screen and (max-width: 500px) {
        .content {
          .left-container {
            .links {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              .monday-link {
                margin-bottom: 20px;
              }
            }
          }
        }
      }
`



    return <AboutUsStyled>
        <SectionTitle title={t("InformationSystem.sections.about-us.heading")}/>
        <div className="content">
            <div className="left-container">
                <div className="title">
                    {/* 2 words of this string need to be styled the same. Their wrapper is the char '%' */}
                    {usePartColor(t("InformationSystem.sections.about-us.title"), "var(--purple1)")}
                </div>
                <div className="desc">
                    <Trans>InformationSystem.sections.about-us.description</Trans>
                </div>
                <div className="links">
                    <a className="monday-link" href="information-system/monday">{t("monday.heading")}</a>
                    <a className="salesforce-link" href="information-system/salesforce">{t("salesforce.heading")}</a>
                </div>
            </div>
            {screenSize > 960 && <div className="right-container">
                <img src={t("InformationSystem.sections.about-us.img")} alt="" />
            </div>}
        </div>
    </AboutUsStyled>
}